import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
    userDetailsReducer,
    userLoginReducer,
    userRegisterReducer,
    userUpdateProfileReducer,
    userTransactionsReducer,
    userDonutStatsReducer,
    userDonutStats7DReducer,
    userGrowthReducer,
    userBalanceStatsReducer,
    getReferralStatusReducer,
    getUserTariffReducer,
    getNotificationsReducer,
    getTariffsReducer,
    getUserWalletActionsReducer,
    getCurrenciesListReducer,
    forgotPassowordReducer,
    changePasswordReducer,
    deleteImageReducer,
    userBalanceInfoReducer
} from "./Reducers/userReducers";
import { confirmDepositReducer, createDepositReducer, createWithdrawReducer, getCommisionWithdrawReducer, getCurrenciesReducer, getTransactionReducer, getWalletsReducer, makeRefTransferReducer } from "./Reducers/transactionReducers";

const reducer = combineReducers({
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    updateProfile: userUpdateProfileReducer,
    userTransactions:userTransactionsReducer,
    userDonutStats:userDonutStatsReducer,
    userDonutStats7D:userDonutStats7DReducer,
    
    userGrowth:userGrowthReducer,
    userBalance:userBalanceStatsReducer,
    currencies: getCurrenciesReducer,
    walletsList:getWalletsReducer,
    orderDepositCreate:createDepositReducer,
    userTransaction:getTransactionReducer,
    makeTransfer:makeRefTransferReducer,
    userReferralStatus:getReferralStatusReducer,
    userWithdraw:createWithdrawReducer,
    userTariff:getUserTariffReducer,
    userConfirmDeposit:confirmDepositReducer,
    userNotifications:getNotificationsReducer,
    tariffsList:getTariffsReducer,
    userWalletActions:getUserWalletActionsReducer,
    getCurrenciesUnsecure:getCurrenciesListReducer,
    commisionWithdraw:getCommisionWithdrawReducer,
    resetPassword:forgotPassowordReducer,
    passwordReset:changePasswordReducer,
    resetImage:deleteImageReducer,
    balanceInfo:userBalanceInfoReducer
    
    // Reducers
});

const userInfoFromLocalStorage = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
const initialState = {
    userLogin: { userInfo: userInfoFromLocalStorage },
};

const middleware = [thunk];

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
