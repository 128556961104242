import React, { Fragment, useEffect, useState } from "react";
import Chart from "react-apexcharts";

import "../../css/pages/dashboard.css";
import Balance from "./balance";
import Transaction from "./transaction";
import Growth from "./growth";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, getUserTransactions } from "../../Redux/Actions/userActions";
import mobxStore from "../../mobx/mobxStore";
import { observer } from "mobx-react-lite";
import CoinsPrice from "./coinsPrice";
const Dashboard = observer(() => {
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state.userDetails);
    const { user, loading } = userDetails;
    const userTransactions = useSelector((state) => state.userTransactions);
    const { transactions, loading:loadingTransactions } = userTransactions;
    const { variables } = mobxStore;
    useEffect(() => {
        dispatch(getUserDetails());
        dispatch(getUserTransactions());
    }, [dispatch]);

    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    console.log(userTransactions);
    
    return (
        <Fragment>
            <div
                className={`dashboard dashboard--v1 ${
                    variables.depositModalOpened ? "blur-modal" : ""
                }`}
            >
                <div className="container container--dashboard">
                    <div className="dashboard__group">
                        <Balance userDetails={userDetails} transactions={transactions}/>

                        <Transaction />

                        <Growth />
                    </div>
                </div>
            </div>
        </Fragment>
    );
});

export default Dashboard;

function getWalletDonutChart(series, colors) {
    var options = {
        series: series,
        options: {
            colors: colors,
            chart: {
                type: "donut",
                width: "163px",
                height: "163px",
                redrawOnWindowResize: true,
                redrawOnParentResize: true,
                toolbar: { show: false },
                zoom: {
                    enabled: false,
                },
                stacked: false,
            },
            dataLabels: {
                enabled: false,
            },
            legend: { show: false },
            tooltip: {
                enabled: false,
            },
            stroke: {
                show: true,
                colors: "#1E1F25",
                width: 3,
            },
        },
    };
    return options;
}
