import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { checkIfOneIsTrue } from "../../../helpers/oneofvartrue";
import { observer } from "mobx-react-lite";
import mobxStore from "../../../mobx/mobxStore";
const HeaderHome = 
observer(
() => {
    const {variables}=mobxStore
    const location = useLocation();

    const [overlayOpened, setOverlayOpened] = useState(false);
    const userLogin = useSelector((state) => state.userLogin);
    const userRegister = useSelector((state) => state.userRegister);

    const { userInfo: userInfoLogin } = userLogin;
    const { userInfo: userInfoRegister } = userRegister;

    const toggleOverlay = () => {
        setOverlayOpened((prevState) => {
            // Если новое состояние true, добавляем класс
            if (!prevState) {
                document.body.classList.add("no-scroll");
            } else {
                // Если новое состояние false, удаляем класс
                document.body.classList.remove("no-scroll");
            }
            return !prevState;
        });
    };
    useEffect(()=>{
        setOverlayOpened((prevState)=>{
            if(prevState){
                document.body.classList.remove("no-scroll");
            }
        })


        return ()=>  document.body.classList.remove("no-scroll");;
    },[location]);
    return (
        <Fragment>
            <header className={`home_container ${checkIfOneIsTrue(variables)?"blur-modal":""}`}>
                <Link
                    className="text-blue platform_name"
                    style={{ cursor: "pointer" }}
                    onClick={() => window.scroll(0, 0)}
                    to="/"
                >
                    P2P ARBITRAZ
                </Link>
                <nav className="header_menu">
                    {location.pathname == "/"||location.pathname == "/privacy-policy"||location.pathname == "/terms" ? (
                        <ul>
                            <li>
                                <a href="/#about-us">About us</a>
                            </li>
                            <li>
                                <a href="/#how-it-works">How it works</a>
                            </li>
                            <li>
                                <a href="/#choose-plan">Tariff plans</a>
                            </li>
                            <li>
                                <a href="/#reviews">Reviews</a>
                            </li>
                            <li>
                                <a href="https://t.me/emma_clark_p2p">
                                    Contacts
                                </a>
                            </li>
                        </ul>
                    ) : (
                        <ul>
                            <li>
                                <Link to="/dashboard">Dashboard</Link>
                            </li>
                            <li>
                                <Link to="/statistics">Statistics</Link>
                            </li>
                            <li>
                                <Link to="/wallet">Wallet</Link>
                            </li>
                            <li>
                                <Link to="/#choose-plan">Tariff plans</Link>
                            </li>
                            <li>
                                <Link to="/#faq">FAQ</Link>
                            </li>
                            <li>
                                <Link to="/profile">Profile</Link>
                            </li>
                        </ul>
                    )}
                </nav>
                <div className="language-select">
                    <img
                        src="/images/flag/gb.svg"
                        width="22px"
                        className="language-choosen"
                    />
                </div>

                {userInfoLogin || userInfoRegister ? (
                    <Link to="/dashboard" className="home_btn join_now">
                        My Account
                    </Link>
                ) : (
                    <Link to="/sign-up" className="home_btn join_now">
                        Join now
                    </Link>
                )}

                <div
                    id="nav-icon1"
                    className={`burger-menu ${overlayOpened ? "open" : ""}`}
                    onClick={toggleOverlay}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </header>

            <div className={`overlay ${overlayOpened ? "active" : ""}`}>
                <div className="overlay-content home_container">
                    {location.pathname == "/" ? (
                        <Fragment>
                            <a href="/#about-us">About us</a>
                            <a href="/#how-it-works">How it works</a>
                            <a href="/#choose-plan">Tariff plans</a>
                            <a href="/#reviews">Reviews</a>
                            <a href="https://t.me/emma_clark_p2p">Contacts</a>
                            <div className="language-select">
                                <img
                                    src="/images/flag/gb.svg"
                                    width="22px"
                                    className="language-choosen"
                                />
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Link to="/dashboard">Dashboard</Link>
                            <Link to="/statistics">Statistics</Link>
                            <Link to="/wallet">Wallet</Link>
                            <Link to="/#choose-plan">Tariff plans</Link>
                            <Link to="/#faq">FAQ</Link>
                            <Link to="/profile">Profile</Link>
                            <div className="language-select">
                                <img
                                    src="/images/flag/gb.svg"
                                    width="22px"
                                    className="language-choosen"
                                />
                            </div>
                        </Fragment>
                    )}
                    {userInfoLogin || userInfoRegister ? (
                        <Link to="/dashboard" className="home_btn join_now">
                            My Account
                        </Link>
                    ) : (
                        <Link to="/sign-up" className="home_btn join_now">
                            Join now
                        </Link>
                    )}
                </div>
            </div>
        </Fragment>
    );
});

export default HeaderHome;
