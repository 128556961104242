import { makeAutoObservable } from "mobx";

class Store {
    variables = {
        depositModalOpened: false,
        withdrawModalOpened: false,
        growthOpened: false,
        transferConfirmModalOpened: false,
        deleteAccountModalOpened: false,
        openedTriangleWindow: false,
        onlyMondayOpened:false
    };

    constructor() {
        makeAutoObservable(this);
    }

    setDepositModalOpened = (value) => {
        if (value !== this.variables.depositModalOpened) {
            this.variables = { ...this.variables, depositModalOpened: value };
        }
    };
    setWithdrawModalOpened = (value) => {
        if (value !== this.variables.withdrawModalOpened) {
            this.variables = { ...this.variables, withdrawModalOpened: value };
        }
    };
    setGrowthOpened = (value) => {
        if (value !== this.variables.growthOpened) {
            this.variables = { ...this.variables, growthOpened: value };
        }
    };
    setTransferOpened = (value) => {
        if (value !== this.variables.transferConfirmModalOpened) {
            this.variables = {
                ...this.variables,
                transferConfirmModalOpened: value,
            };
        }
    };
    setDeleteAccountModalOpened = (value) => {
        if (value !== this.variables.deleteAccountModalOpened) {
            this.variables = {
                ...this.variables,
                deleteAccountModalOpened: value,
            };
        }
    };

    closeTriangle = () => {
        if (this.variables.openedTriangleWindow !== -1) {
            this.variables = {
                ...this.variables,
                openedTriangleWindow: false,
            };
        }
    };
    openTriangle = (value) => {
        if (value !== this.variables.openedTriangleWindow) {
            this.variables = {
                ...this.variables,
                openedTriangleWindow: true,
            };
        }
    };
    setOnlyModayOpened = (value) => {
        if (value !== this.variables.onlyMondayOpened) {
            this.variables = { ...this.variables, onlyMondayOpened: value };
        }
    };
}

export default new Store();
