import React from "react";
import OutsideAlerter from "../../helpers/outsideAlerter";
import Toast from "../Toast/toast";

const WithdrawRequested = (props) => {
    const {
        wallet,
        amount,
        onOutsideClickHandler,
        getReceiveAmount,
        commisionUSD,
        currencySelected,
        currencies
    } = props;

    return (
        <div className="modal-window">
            <Toast/>
            <OutsideAlerter
                className={`advance-card advance-card--type-1-v1 aos-init aos-animate `}
                onOutsideClick={() => {
                    onOutsideClickHandler();
                }}
            >
                <div className="advance-card__title">
                    <h6 className="advance-card__title-text">
                        The application has been successfully created.
                    </h6>
                </div>
               

                <div className="advance-card__forms-currency">
                    <div className="advance-card__forms-currency--pay">
                        <p className="advance-card__forms-currency__title">
                            You will receive
                        </p>
                        <div className="forms-currency forms-currency--advance-card">
                            <div className="forms-group forms-group--value">
                                <input
                                    type="number"
                                    disabled
                                    value={(amount - commisionUSD).toFixed(2)}
                                    className="form-control forms-currency__value"
                                />
                            </div>

                            <div
                                className="forms-group forms-group--currency js-forms-group--currency"
                                id="custom-select-currency-2"
                            >
                                <div
                                    className="forms-group__items selected"
                                    data-selected="usd"
                                >
                                    <img
                                        className="fg-items__icon"
                                        src="/assets/media/images/icons/usd.svg"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="advance-card__forms-currency--get">
                        <p className="advance-card__forms-currency__title">
                            To the {currencySelected=="Card"?"card":"wallet"}
                        </p>
                        <div className="forms-currency forms-currency--advance-card">
                            <div className="forms-group forms-group--value">
                                <input
                                    type="text"
                                    disabled
                                    value={wallet}
                                    className="form-control forms-currency__value"
                                />
                            </div>

                            <div
                                className="forms-group forms-group--currency js-forms-group--currency"
                                id="custom-select-currency-1"
                            >
                                <div
                                    className="forms-group__items selected"
                                    data-selected="usd"
                                >
                                    <img
                                        className="fg-items__icon"
                                        src={`/assets/media/images/icons/${currencies.find(item=>item.name==currencySelected).icon}`}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="advance-card__title">
                    <p className="advance-card__form-title-text">
                        Your balance has been debited:<br/> {(amount-0).toFixed(2)} USD
                    </p>
                </div>
                <div className="advance-card__title">
                    <p>
                        You will receive: {(amount - commisionUSD).toFixed(2)}
                        USD
                    </p>
                </div>

                {currencySelected=="Card"?  <div className="advance-card__title">
                    <p>
                    To complete the transaction, write to our <a href="https://t.me/emma_clark_p2p" target="_blank">manager</a>
                    </p>
                </div>:null}

                <button
                    className="advance-card__button btn btn-primary btn-pill"
                    onClick={() => onOutsideClickHandler()}
                >
                    Close
                </button>
            </OutsideAlerter>
        </div>
    );
};

export default WithdrawRequested;
