import React from "react";
import OutsideAlerter from "../../helpers/outsideAlerter";
import { observer } from "mobx-react-lite";
import mobxStore from "../../mobx/mobxStore";
const OnlyMonday=observer(()=>{
    const {variables,setOnlyModayOpened,setWithdrawModalOpened}=mobxStore
    const onlyMondayHandlerClose=()=>{
        setOnlyModayOpened(false);
        setWithdrawModalOpened(false);
       }


      if(!variables.onlyMondayOpened){
        return <></>
      }
return (
        <div className="modal-window">
        <OutsideAlerter
            className={`advance-card advance-card--type-1-v1 aos-init aos-animate `}
            onOutsideClick={() => {
                onlyMondayHandlerClose();
            }}
        >
           
           

          
            <div className="advance-card__title">
                <p className="advance-card__form-title-text">
                Withdrawal requests are accepted every Sunday for the previous week. Please submit withdrawal requests on Sunday.
                </p>
            </div>
            <div className="advance-card__title">
                <p className="advance-card__form-title-text">
WARNING! The available limit is no more than one request from your personal balance and one request from your net income.
                </p>
            </div>

            <button
                className="advance-card__button btn btn-primary btn-pill"
                onClick={() => onlyMondayHandlerClose()}
            >
                OK
            </button>
        </OutsideAlerter>
    </div>
)
});


export default OnlyMonday;