import React, { useEffect, useState } from "react";
import Toast from "./Toast/toast";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../Redux/Actions/userActions";
import { toast } from "react-toastify";
import { Toastobjects } from "../App";
const ForgotPassword=()=>{
        const toastId = React.useRef(null);
        const dispatch=useDispatch()
        const resetPassword=useSelector(state=>state.resetPassword);
  const [email, setEmail] = useState("");
    const {
        loading,
forgot,
error,
    }=resetPassword;
    useEffect(() => {
        document.documentElement.classList.add("sign-in-page");


        return ()=>  document.documentElement.classList.remove("sign-in-page");
    }, []);


    const submitHandler=(e)=>{
        e.preventDefault();
        dispatch(forgotPassword({email}));
    }
 
    
    useEffect(()=>{
        if(error){
                if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.error(
                          error,Toastobjects
                        );
                    }

        }
    },[resetPassword]);

        return (
                <div className="sign-in">
                <Toast />
                <div className="sign-in-card">
                    <div className="sign-in__heading">
                    <Link
                        className="text-blue platform_name"
                        href="/"
                        style={{ cursor: "pointer" }}
                        onClick={() => window.scroll(0, 0)}
                    >
                        P2P ARBITRAZ
                    </Link>
                        <p
                            className="fb-sm sign-in__title "
                          
                        >
                            Forgot your password? Don't worry
                        </p>
                    </div>
    
                    <form onSubmit={submitHandler} className="sign-in__forms">
                        <div
                            className="forms-group"
                            
                        >
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                placeholder="Enter your email address"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                      
    
                      
    
                        <button
                            type="submit"
                            className="btn btn-primary btn-pill btn-submit"
                          
                            disabled={loading ||(forgot&&forgot.success)}
                        >
                            {loading?
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            :null
                        }
                         
                            Reset password
                        </button>
                        {!loading&&forgot&&forgot.success?
                        <p  style={{textAlign:"center",fontSize:"18px"}}>The new password was sent to the mail</p>:null}
                        <p
                            className="fb-sm sign-up-info "
                            
                        >
                            Remembered your password?
                            <Link to="/sign-in" className="sign-up-link">
                                Sign in
                            </Link>
                        </p>
                    </form>
                </div>
            </div>
        )
};

export default ForgotPassword;