import React, { Fragment } from "react";
import Footer from "./home/elements/footer";
import HeaderHome from "./home/elements/headerHome";
import "./privacy-policy.css";
const PrivacyPolicy = () => {

    window.scroll(0,0);

    return (
        <Fragment>
 <HeaderHome/>
        <section className="privacy-policy" style={{ padding: "5rem 0" }}>
            <div className="container">
            
<h1>Privacy Policy</h1>
<p>Last updated: September 9, 2024</p>

<p>This Privacy Policy describes our policies and procedures regarding the collection, use and disclosure of your information when you use our website https://p2p-arbitraz.com (the "Service"). It also tells you about your privacy rights and how the law protects you.</p>

<p>We use your personal data to provide and improve the Service. By using the Service, you consent to the collection and use of information in accordance with this Privacy Policy.</p>

<h2>Interpretation and definition</h2>
<h3>Interpretation</h3>
<p>Capitalized words have the meanings set forth in the following terms. The following definitions will have the same meaning regardless of whether they appear in the singular or plural.
</p>

<h2>Definition</h2>
<p>For the purposes of this Privacy Policy:
</p>

<p>Account means a unique account created for You to access our Service or parts thereof.</p>
<p>Affiliate means an entity that controls, is controlled by or is under common control with one of the parties.</p>
<p>The Company (referred to as "Company", "We", "Us" or "Our" in this Agreement) refers to P2P Arbitraz, whose office is located in Burj Khalifa, Dubai, UAE.</p>
<p>Cookies are small files placed on your computer, mobile device or other device by a website that contain information about your browsing history.</p>
<p>The country belongs to: United Arab Emirates.</p>
<p>Device means any device that can access the Service, such as a computer, mobile phone or tablet.</p>
<p>Personal data means any information relating to an identified or identifiable individual.</p>
<p>Service means the website.</p>
<p>Service Provider means any natural or legal entity that processes data on behalf of the Company.</p>
<p>Usage Data refers to data collected automatically or generated as a result of using the Service.</p>
<p>Collection and use of your personal data</p>
<h2>Types of data collected</h2>
<h3>Personal data</h3>
<p>When using our Service, we may ask you to provide us with certain information that can be used to identify or contact you. This may include, but is not limited to:
</p>

<ul>
    <li>E-mail address</li>
    <li>Name and surname</li>
    <li>Phone number</li>
    <li>Address, state, region, zip code, city</li>
    <li>Usage data</li>
    <li>Usage data is collected automatically when you use the Service. They may include information such as your device's IP address, browser type, browser version, the pages of our Service you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.</li>

</ul>

<h2>Cookies and tracking technologies</h2>
<p>We use cookies and similar technologies to track activity on our Service and store certain information. You can set your browser to refuse all cookies or to notify you when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some parts of our Service.</p>

<h3>Use of your personal data</h3>
<p>The company may use your personal data for the following purposes:</p>

<ul>
   <li> To provide and support our Service.</li>
<li>To manage your account.</li>
<li>To contact you.</li>
<li>To provide you with news, special offers and general information about other products and services.</li>
<li>To manage your requests.</li>
<li>Storage of your personal data</li>
<li>The Company will retain your personal data only for the time necessary for the purposes set forth in this Privacy Policy.</li>
</ul>

<h2>Transfer of your personal data</h2>
<p>Your information, including personal data, is processed at the Company's offices and other locations where the parties involved in the processing are located. This means that this information may be transferred to — and stored on — computers located outside of your country.</p>

<h3>Deleting your personal data</h3>
<p>You have the right to delete or ask us to help you delete personal data we have collected about you.</p>

<h3>Disclosure of your personal data</h3>
<p>The Company may disclose your personal data in the event of a merger, acquisition or sale of assets, as well as in cases where it is required by law or upon request by public authorities.</p>

<h3>Security of your personal data</h3>
<p>The security of your personal data is important to us, but please remember that no method of transmission over the Internet or method of electronic storage is 100% secure.</p>

<h3>Changes to this Privacy Policy</h3>
<p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting a new Privacy Policy on this page.</p>

<h3>Contact us</h3>
<p>If you have any questions about this Privacy Policy, you can contact us:</p>

<ul><li>By e-mail: support@p2p-arbitraz.com</li>
<li>By visiting this page on our website: https://p2p-arbitraz.com/contact</li>
</ul>
            </div>
        </section>
        <Footer/>
        </Fragment>
    );
};

export default PrivacyPolicy;
