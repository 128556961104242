import React from "react";
import Chart from "react-apexcharts";

const Donut=(props)=>{
        const {loading,stats,donutChart}=props;


        return ( <div className="complete-card__wallet-data__chart">
                <div
                    id="complete-card__wallet-data__donut-chart-1"
                    className="complete-card__wallet-data__donut-chart-render"
                    style={{ minHeight: "139.65px" }}
                >
                    {donutChart ? (
                        <Chart
                            options={donutChart.options}
                            series={donutChart.series}
                            type="donut"
                            
                        />
                    ) : null}
                </div>
                <div
                    className="complete-card__wallet-data__donut-chart-label"
                    style={
                        !loading && typeof loading !== "undefined"
                            ? {}
                            : { display: "none" }
                    }
                >
                    <img
                        src="/assets/media/images/icons/arrow-bullish.svg"
                        alt=""
                        className="complete-card__wallet-data__donut-chart-label-icon"
                    />
                    <p className="fd-sm fd-sm--bold complete-card__wallet-data__donut-chart-label-text" style={{fontSize:"1rem"}}>
                        {!loading && typeof loading !== "undefined"
                            ? (stats.income24h * 100).toFixed(2)
                            : "0.00"}
                        %
                    </p>
                </div>
            </div>)
}


export default Donut;