import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserTransactions } from "../../Redux/Actions/userActions";
import { uniqid } from "../../helpers/uniqid";
import { getIconFromServer } from "../../helpers/getImageFromServer";
import TriangleWindow from "./triangleWindow";
import "./exchange.css";
import OutsideAlerter from "../../helpers/outsideAlerter";
import mobxStore from "../../mobx/mobxStore";
const Transactions = () => {
    const {closeTriangle,variables}=mobxStore;
    const dispatch = useDispatch();
    const [openedTriangleWindow, setOpenedTriangleWindow] = useState(-1);
    const [filterProps, setFilterProps] = useState({
        allTransactions: true,
        onlyTriangles: false,
        percentBig:false,
        percentSmall:false
    });
    const [priceOpened, setPriceOpened] = useState(false);
    const [incomeFrom, setIncomeFrom] = useState(0);
    const [incomeTo, setIncomeTo] = useState(100);

    const userTransactions = useSelector((state) => state.userTransactions);
    useEffect(() => {
        dispatch(getUserTransactions({ ...filterProps }));
    }, [dispatch, filterProps]);

    const { transactions, loading } = userTransactions;

    

    const setIncomeFromHandler = (amount) => {
        try {
            setFilterHandler("priceEnabled", true);

            if (parseFloat(amount) < 0) {
                setIncomeFrom(0);
                return;
            } else if (parseFloat(amount) > 100) {
                setIncomeFrom(100);
                return;
            }

            setIncomeFrom(amount);
        } catch (error) {
            setIncomeFrom(0);
        }
    };

    const setIncomeToHandler = (amount) => {
        try {
            setFilterHandler("priceEnabled", true);
            if (parseFloat(amount) < 0) {
                setIncomeTo(0);
                return;
            } else if (parseFloat(amount) > 100) {
                setIncomeTo(100);
                return;
            }
            setIncomeTo(amount);
        } catch (error) {
            console.log(error);
            setIncomeTo(100);
        }
    };

    const setFilterHandler = (key, state) => {
        const filterPropsCopy = JSON.parse(JSON.stringify(filterProps));
        switch (key) {
            case "allTransactions":
                filterPropsCopy.allTransactions = state;
                filterPropsCopy.onlyTriangles = false;
                filterPropsCopy.priceEnabled = false;
                break;
            case "onlyTriangles":
                filterPropsCopy.onlyTriangles = state;
                filterPropsCopy.allTransactions = false;
                filterPropsCopy.percentBig = false;
                filterPropsCopy.percentSmall = false;
                break;
            case "percentBig":
                filterPropsCopy.percentBig = state;
                filterPropsCopy.percentSmall = false;
                filterPropsCopy.onlyTriangles = false;
                filterPropsCopy.allTransactions = false;
                break;
                case "percentSmall":
                    filterPropsCopy.percentSmall = state;
                    filterPropsCopy.percentBig = false;
                    filterPropsCopy.onlyTriangles = false;
                    filterPropsCopy.allTransactions = false;
                    break;
            case "resetFilters":
                setIncomeFrom(0);
                setIncomeTo(100);
                filterPropsCopy.allTransactions = true;
                filterPropsCopy.onlyTriangles = false;
                filterPropsCopy.priceEnabled = false;
                filterPropsCopy.percentBig = false;
                filterPropsCopy.percentSmall = false;
                break;
        }
        setFilterProps(filterPropsCopy);
    };

    if (incomeFrom == 0 && incomeTo == 100 && filterProps.priceEnabled) {
        setFilterHandler("priceEnabled", false);
    }

   
   
    

    return (
        <div
            className={`table-wrapper table--type-3 aos-init aos-animate ${
                typeof loading === "undefined" || loading
                    ? "blur-loading2x"
                    : ""
            }`}
            data-aos="fade-up"
        >
            <div className="table__title">
                <h6 className="table__title-text">Transactions</h6>
                <nav className="navbar">
                    <div
                        className="table__title-tabs"
                        id="pills-tab"
                        role="tablist"
                    >
                        <li
                            className="nav-item"
                            role="presentation"
                            onClick={() =>
                                setFilterHandler("allTransactions", true)
                            }
                        >
                            <p
                                className={`fb-regular fb-regular--bold table__title-tab ${
                                    filterProps.allTransactions ? "active" : ""
                                }`}
                                data-bs-toggle="pill"
                                data-bs-target="#pills-market-table-all"
                                role="tab"
                                aria-controls="pills-market-table-all"
                                aria-selected="true"
                            >
                                All Transactions
                            </p>
                        </li>
                        <li
                            className="nav-item"
                            role="presentation"
                            onClick={() =>
                                setFilterHandler(
                                    "onlyTriangles",
                                    !filterProps.onlyTriangles
                                )
                            }
                        >
                            <p
                                className={`fb-regular fb-regular--bold table__title-tab ${
                                    filterProps.onlyTriangles ? "active" : ""
                                }`}
                                id="pills-market-table-gainers-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-market-table-gainers"
                                role="tab"
                                aria-controls="pills-market-table-gainers"
                                aria-selected="false"
                                tabIndex={-1}
                            >
                                Only triangles
                            </p>
                        </li>
                        <li
                            className="nav-item"
                            role="presentation"
                            onClick={() =>
                                setFilterHandler(
                                    "percentBig",
                                    !filterProps.percentBig
                                )
                            }
                        >
                            <p
                                className={`fb-regular fb-regular--bold table__title-tab ${
                                    filterProps.percentBig ? "active" : ""
                                }`}
                                id="pills-market-table-gainers-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-market-table-gainers"
                                role="tab"
                                aria-controls="pills-market-table-gainers"
                                aria-selected="false"
                                tabIndex={-1}
                            >
                                First the big %
                            </p>
                        </li>
                        <li
                            className="nav-item"
                            role="presentation"
                            onClick={() =>
                                setFilterHandler(
                                    "percentSmall",
                                    !filterProps.percentSmall
                                )
                            }
                        >
                            <p
                                className={`fb-regular fb-regular--bold table__title-tab ${
                                    filterProps.percentSmall ? "active" : ""
                                }`}
                                id="pills-market-table-gainers-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-market-table-gainers"
                                role="tab"
                                aria-controls="pills-market-table-gainers"
                                aria-selected="false"
                                tabIndex={-1}
                            >
                                First a small %
                            </p>
                        </li>
                        {/* <li
                            className="nav-item transaction_filter"
                            role="presentation"
                        >
                            <OutsideAlerter
                                onOutsideClick={() => setPriceOpened(false)}
                            >
                                <div className="dropdown">
                                    <p
                                        className={`btn fb-regular fb-regular--bold table__title-tab dropdown-toggle dropdown-toggle--card-type-5 transaction_filter__btn ${
                                            priceOpened ? "show" : ""
                                        } ${
                                            filterProps.priceEnabled
                                                ? "active"
                                                : ""
                                        }`}
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={() =>
                                            setPriceOpened(!priceOpened)
                                        }
                                    >
                                        Прибыль
                                        <img
                                            src="../assets/media/images/icons/arrow-down.svg"
                                            alt=""
                                        />
                                    </p>
                                    <ul
                                        className={`dropdown-menu ${
                                            priceOpened ? "show" : ""
                                        }`}
                                        aria-labelledby="dropdownMenuButton1"
                                    >
                                        <li>
                                        Показать сначала большие %
                                            <div className="forms-currency forms-currency--advance-card">
                                                <div className="forms-group forms-group--value">
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        max={100}
                                                        value={incomeFrom}
                                                        onChange={(e) =>
                                                            setIncomeFromHandler(
                                                                e.target.value
                                                            )
                                                        }
                                                        className="form-control forms-currency__value"
                                                    />
                                                </div>
                                                <div className="forms-currency__line" />
                                                <div
                                                    className="forms-group forms-group--currency js-forms-group--currency"
                                                    id="custom-select-currency-1"
                                                >
                                                    <div
                                                        className="forms-group__items selected"
                                                        data-selected="usd"
                                                    >
                                                        %
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                        Показать сначала большие %
                                            <div className="forms-currency forms-currency--advance-card">
                                                <div className="forms-group forms-group--value">
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        max={100}
                                                        value={incomeTo}
                                                        onChange={(e) =>
                                                            setIncomeToHandler(
                                                                e.target.value
                                                            )
                                                        }
                                                        className="form-control forms-currency__value"
                                                    />
                                                </div>
                                                <div className="forms-currency__line" />
                                                <div
                                                    className="forms-group forms-group--currency js-forms-group--currency"
                                                    id="custom-select-currency-1"
                                                >
                                                    <div
                                                        className="forms-group__items selected"
                                                        data-selected="usd"
                                                    >
                                                        %
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </OutsideAlerter>
                        </li> */}

                        <li
                            className="nav-item"
                            role="presentation"
                            onClick={() =>
                                setFilterHandler("resetFilters", true)
                            }
                        >
                            <p
                                className="fb-regular fb-regular--bold table__title-tab"
                                id="pills-market-table-tradeble-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-market-table-tradeble"
                                role="tab"
                                aria-controls="pills-market-table-tradeble"
                                aria-selected="false"
                                tabIndex={-1}
                            >
                                <img src="/assets/media/images/icons/exchange-white.svg" />
                            </p>
                        </li>
                    </div>
                </nav>
            </div>
            <div
                className={`tab-content ${
                    typeof loading !== "undefined" &&
                    !loading &&
                    transactions.length == 0
                        ? "blur-loading2x"
                        : ""
                }`}
            >
                <div
                    className="tab-pane fade show active"
                    id="pills-market-trades-open-order"
                    role="tabpanel"
                    aria-labelledby="pills-market-trades-open-order-tab"
                >
                    <div
                        className="table-container statistics-content"
                        style={{ maxHeight: "600px" }}
                    >
                        <table className="table table-content">
                            <thead style={{zIndex:"1"}}>
                                <tr>
                                    <th
                                        className="fb-regular table__head th-time"
                                      //  style={{ width: "10%" }}

                                    >
                                        Time
                                    </th>
                                    <th
                                        className="fb-regular table__head th-coin"
                                        style={{textAlign:"center"}}
                                   //     style={{ width: "8%" }}
                                    >
                                        Coin
                                    </th>
                                    <th
                                        className="fb-regular table__head"
                                        style={{textAlign:"center",width:"90px"}}
                                   //     style={{ width: "5%",textAlign:"center" }}
                                    >
                                        BUY
                                    </th>
                                    <th
                                        className="fb-regular table__head th-stock"
                                   //     style={{ width: "15%" }}
                                
                                    >
                                        Stock
                                    </th>
                                    <th
                                        className="fb-regular table__head"
                                        style={{textAlign:"center",width:"90px"}}
                                   //     style={{ width: "5%",textAlign:"center" }}
                                    >
                                        SELL
                                    </th>
                                    <th
                                        className="fb-regular table__head th-stock"
                                  //      style={{ width: "13%" }}
                                  
                                    >
                                        Stock
                                    </th>
                                    <th
                                        className="fb-regular table__head th-difference"
                                        style={{textAlign:"center"}}
                                  //      style={{ width: "10%" }}
                                    >
                                        Difference
                                    </th>
                                    <th
                                        className="fb-regular table__head th-triangle"
                                    //    style={{ width: "5%" }}
                                    ></th>
                                </tr>
                            </thead>
                            <tbody style={{position:"relative"}}>
                                {!loading &&
                                typeof loading !== "undefined" &&
                                transactions.length > 0 ? (
                                    transactions
                                        .filter(
                                            (transaction) =>
                                                typeof transaction.referrer !==
                                                "undefined"
                                        )
                                        .map((transaction, index) => {
                                            try {
                                                return (
                                                    <tr key={uniqid()} className="transaction-item">
                                                        <td className="td-time">
                                                            <p className="fb-regular">
                                                                {getTimeHHMMSS(
                                                                    transaction.date
                                                                )}
                                                            </p>
                                                        </td>
                                                        <td style={{textAlign:"center"}}>
                                                            <p className="fb-regular " style={{fontWeight:"bold"}}>
                                                                {
                                                                    transaction
                                                                        .currency
                                                                        .symbol
                                                                }
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p
                                                                className="fb-regular text-bearish"
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                      color:"#FFB300",
                                                                      fontWeight:"bold"
                                                                }}
                                                            >
                                                                ${transaction.referrer.amount.toFixed(
                                                                    2
                                                                )}
                                                            </p>
                                                        </td>
                                                        <td style={{width:"90px"}}>
                                                            <a href={transaction.referrer.stock.link} target="_blank">
                                                            <img
                                                                src={getIconFromServer(
                                                                    transaction
                                                                        .referrer
                                                                        .stock
                                                                        ? transaction
                                                                              .referrer
                                                                              .stock
                                                                              .logo
                                                                        : ""
                                                                )}
                                                                height="30px"
                                                                style={{
                                                                    verticalAlign:
                                                                        "sub",
                                                                       
                                                                }}
                                                            />
                                                            </a>
                                                           
                                                        </td>
                                                        <td>
                                                            <p
                                                                className="fb-regular text-bullish"
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                       color:"#D53032",
                                                                       fontWeight:"bold"
                                                                }}
                                                            >
                                                                ${transaction.amount.toFixed(
                                                                    2
                                                                )}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <a href={    transaction
                                                                        .stock
                                                                        .link} target="_blank">
                                                            <img
                                                                src={getIconFromServer(
                                                                    transaction
                                                                        .stock
                                                                        .logo
                                                                        ? transaction
                                                                              .stock
                                                                              .logo
                                                                        : ""
                                                                )}
                                                                height="30px"
                                                                style={{
                                                                    verticalAlign:
                                                                        "sub",
                                                                }}
                                                            />
                                                            </a>
                                                         
                                                        </td>
                                                        <td style={{textAlign:"center",textWrap:"nowrap"}} className="td-difference">
                                                            <p className="fb-regular text-bullish" style={{fontWeight:"bold"}}>
                                                                ${(
                                                                    transaction.amount -
                                                                    transaction
                                                                        .referrer
                                                                        .amount
                                                                ).toFixed(
                                                                    2
                                                                )}{" "}
                                                                (
                                                                {(
                                                                    ((transaction.amount -
                                                                        transaction
                                                                            .referrer
                                                                            .amount) /
                                                                        transaction
                                                                            .referrer
                                                                            .amount) *
                                                                    100
                                                                ).toFixed(2)}
                                                                %)
                                                            </p>
                                                        </td>
                                                       <TriangleWindow index={index} isTriangle={transaction.isTriangle}/>
                                                    </tr>
                                                );
                                            } catch (error) {
                                                return null;
                                            }
                                        })
                                ) : (
                                    <React.Fragment>
                                        <tr>
                                            <td>
                                                <p className="fb-regular">
                                                    18:23:30
                                                </p>
                                            </td>
                                            <td>
                                                <p className="fb-regular ">
                                                    XRP
                                                </p>
                                            </td>
                                            <td>
                                                <p className="fb-regular text-bearish">
                                                    643.11
                                                </p>
                                            </td>
                                            <td>
                                                <p className="fb-regular text-bullish">
                                                    1359.46
                                                </p>
                                            </td>
                                            <td>
                                                <p className="fb-regular text-bullish">
                                                    111.39%
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="fb-regular">
                                                    18:23:30
                                                </p>
                                            </td>
                                            <td>
                                                <p className="fb-regular ">
                                                    XRP
                                                </p>
                                            </td>
                                            <td>
                                                <p className="fb-regular text-bearish">
                                                    643.11
                                                </p>
                                            </td>
                                            <td>
                                                <p className="fb-regular text-bullish">
                                                    1359.46
                                                </p>
                                            </td>
                                            <td>
                                                <p className="fb-regular text-bullish">
                                                    111.39%
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="fb-regular">
                                                    18:23:30
                                                </p>
                                            </td>
                                            <td>
                                                <p className="fb-regular ">
                                                    XRP
                                                </p>
                                            </td>
                                            <td>
                                                <p className="fb-regular text-bearish">
                                                    643.11
                                                </p>
                                            </td>
                                            <td>
                                                <p className="fb-regular text-bullish">
                                                    1359.46
                                                </p>
                                            </td>
                                            <td>
                                                <p className="fb-regular text-bullish">
                                                    111.39%
                                                </p>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="table__vignette" />
        </div>
    );
};

export default Transactions;

function getTimeHHMMSS(date) {
    const now = new Date(date);

    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0");

    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return <Fragment>{day}.{month} <br/>{hours}:{minutes}:{seconds}</Fragment>;
}
