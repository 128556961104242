import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserWalletActions } from "../../Redux/Actions/userActions";
import { Link } from "react-router-dom";
import mobxStore from "../../mobx/mobxStore";
import { uniqid } from "../../helpers/uniqid";
const WalletActions = () => {
        const {setDepositModalOpened}=mobxStore;
        const dispatch=useDispatch();
        const userWalletActions=useSelector(state=>state.userWalletActions);
        const {transactions,loading,error}=userWalletActions;

        useEffect(()=>{
                dispatch(getUserWalletActions());
        },[dispatch]);

    
    return (
        <div
            className="table-wrapper table--type-3 aos-init aos-animate"
            data-aos="fade-up"
        >
            <div className="table__title">
                <h6 className="table__title-text">Wallet Events</h6>
            </div>
            <div className="tab-content">
                <div
                    className="tab-pane fade show active"
                    id="pills-market-trades-open-order"
                    role="tabpanel"
                    aria-labelledby="pills-market-trades-open-order-tab"
                >
                    <div
                        className="table-container"
                        style={{ maxHeight: "600px" }}
                    >
                        <table className="table table-content">
                            <thead>
                                <tr>
                                    <th className="fb-regular table__head ">
                                    Type
                                    </th>
                                    <th className="fb-regular table__head">
                                    Amount
                                    </th>
                                    <th className="fb-regular table__head">
                                    Wallet
                                    </th>
                                    <th className="fb-regular table__head ">
                                    Created
                                    </th>
                                    <th className="fb-regular table__head">
                                    Status
                                    </th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {!loading &&transactions?transactions.map((transaction)=><tr key={uniqid()}>
                                    <td>
                                        <p className="fb-regular ">{transaction.type}</p>
                                    </td>
                                    <td>
                                        <p className={`fb-regular  ${transaction.type=="Deposit"||transaction.type=="Bonus"||transaction.type=="Referral"?"text-bullish":"text-bearish"}`} style={{fontWeight:"bold"}}>{transaction.type=="Deposit"||transaction.type=="Bonus"||transaction.type=="Referral"?"+":"-"} {transaction.amount}$</p>
                                    </td>
                                    <td>
                                        <p className="fb-regular" style={{maxWidth:"150px",overflowX:"auto"}}>
                                        {()=>{try{

return transaction.type=="Deposit"?transaction.wallet.wallet:transaction.withdrawWallet
}catch(error){
return "";
}}}
                                        </p>
                                    </td>
                                    <td>
                                        <p className="fb-regular td-time">
                                           {convertDateFormat(transaction.date)}
                                        </p>
                                    </td>
                                    <td>
                                        <p className="fb-regular " style={{color:transaction.status==0?"yellow":transaction.status==2?"green":transaction.status==3?"red":"yellow"}}>
                                            {transaction.status==0?"In progress":transaction.status==2?"Confirmed":transaction.status==3?"Rejected":"In progress"}
                                        </p>
                                    </td>
                                    <td style={{display:transaction.status==2||transaction.status==3?"none":""}}>
                                        
                                        {getLinkTransaction(transaction._id,transaction.type)?<Link className="fb-regular " to={getLinkTransaction(transaction._id,transaction.type)}>
                                        View
                                        </Link>:null}
                                        
                                    </td>
                                </tr>).reverse():null}
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
          
        </div>
    );
};

export default WalletActions;


function convertDateFormat(inputDate) {
        // Create a Date object from the input string
        const date = new Date(inputDate);
    
        // Extract date components
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
    
        // Construct the formatted date string
        const formattedDate = <Fragment>{day}.{month}.{year} <br/>{hours}:{minutes}</Fragment>;
    
        return formattedDate;
}



const getLinkTransaction=(id,type)=>{

    switch(type){
        case "Deposit":
            return `?deposit_id=${id}`
            break;
        case "Withdraw":
            return `?withdraw_id=${id}`;
            break;
        default:return null;
    }

}