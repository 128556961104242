import React, { useEffect } from "react";
import "../../css/pages/dashboard.css";
import CoinsPrice from "../dashboard/coinsPrice";
import Transactions from "./transactions";
import Tariffs from "./tariffs";
import mobxStore from "../../mobx/mobxStore";
import { observer } from "mobx-react-lite";
const Exchange = observer(() => {

    const {variables}=mobxStore;

    useEffect(()=>{
        window.scroll(0,0);
    },[]);

    return (
        <div className={`dashboard dashboard--v1 wallet wallet-v2 ${variables.openedTriangleWindow?"blur-modal":""}`} >
        <div className="container container--dashboard">
            <div className="dashboard__group">
                    <CoinsPrice />
                   <Transactions/>
                </div>
            </div>
        </div>
    );
});

export default Exchange;
