import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import mobxStore from "../mobx/mobxStore";
import OutsideAlerter from "../helpers/outsideAlerter";
import {
    createWithdraw,
    getCommisionWithdraw,
    getTransaction,
} from "../Redux/Actions/transactionsActions";

import {
    CREATE_WITHDRAW_RESET,
    GET_TRANSACTION_RESET,
} from "../Redux/Constans/transactionsConstans";
import {
    getReferralStatus,
    getUserDetails,
    getUserTariff,
} from "../Redux/Actions/userActions";
import { useSearchParams } from "react-router-dom";
import WithdrawDefault from "./withdraw/default";
import WithdrawRequested from "./withdraw/requested";
import { Toastobjects } from "../App";
import OnlyMonday from "./withdraw/onlyMonday";
const WithdrawModal = observer((props) => {
    const { toastId, toast } = props;
    const dispatch = useDispatch();
    const { variables, setWithdrawModalOpened,setOnlyModayOpened } = mobxStore;
    const [searchParams, setSearchParams] = useSearchParams();
    const withdrawId = searchParams.get("withdraw_id");
    const [wallet, setWallet] = useState("");
    const [amount, setAmount] = useState(10);
    const [noCommision, setNoCommision] = useState(0);
    const [earned,setEarned]=useState(0);
    const [subType,setSubType]=useState("Deposit");
    const [personalBalance,setPersonalBalance]=useState(0);
    const [commisionUSD, setCommissionUSD] = useState(0);
    const [balance, setBalance] = useState(0);
    const userWithdraw = useSelector((state) => state.userWithdraw);

    const [currencies, setCurrencies] = useState([
        {
            name: "Tether",
            icon: "logo-usdt.svg",
          
        },
        {
            name: "Card",
            icon: "logo-card.svg",
           
        },
    ]);
    const [currencySelectOpened, setCurrencySelectOpened] = useState(false);
    const [currencySelected, setCurrencySelected] = useState("Tether");
    const { loading, success, error } = userWithdraw;
    const userTariff = useSelector((state) => state.userTariff);
    const { loading: loadingTariff, tariff } = userTariff;
    const userTransaction = useSelector((state) => state.userTransaction);
    const commisionWithdraw = useSelector((state) => state.commisionWithdraw);
    const {
        loading: loadingCommision,
        success: successCommision,
        commision,
    } = commisionWithdraw;
    const {
        loading: loadingTransaction,
        transaction,
        error: errorGetTransaction,
    } = userTransaction;

    const withdrawHandler = () => {
        dispatch(createWithdraw({ amount, wallet,currencySelected,type:subType }));
        dispatch(getCommisionWithdraw());
    };
    useEffect(() => {
        dispatch(getUserTariff());
        dispatch(getCommisionWithdraw());
    }, [dispatch]);

    useEffect(() => {
        if (!loading && error) {
            if(error.includes("Withdrawal requests")){
                setOnlyModayOpened(true);
            }
           else if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(error, Toastobjects);
            }
        } else if (!loading && success) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success(
                    "Withdrawal request sent successfully",
                    Toastobjects
                );
            }
            console.log(userWithdraw);
            setCommissionUSD(userWithdraw.withdraw.withdrawCommission);
            
        }
    }, [userWithdraw]);

    useEffect(() => {
        if (typeof withdrawId === "string") {
            dispatch(getTransaction(withdrawId));
            setWithdrawModalOpened(true);
        }
    }, [dispatch, withdrawId]);
    useEffect(() => {
        if (!loadingTransaction && Object.keys(transaction).length !== 0) {
            setAmount(transaction.amount);
            setWallet(transaction.withdrawWallet);
            setCommissionUSD(transaction.withdrawCommission);
           setCurrencySelected(transaction.currency.name);
            
        }
    }, [dispatch, userTransaction]);

    useEffect(() => {
        if (!loadingCommision && successCommision) {
            setBalance(commision.balance);
            setNoCommision(commision.noCommission);
            setEarned(commision.earned)
            setPersonalBalance(commision.personalBalance);
        }
    }, [commisionWithdraw]);

    const onOutsideClickHandler = () => {
        setWithdrawModalOpened(false);
        searchParams.delete("withdraw_id");
        setSearchParams(searchParams);
        dispatch({ type: CREATE_WITHDRAW_RESET });
        dispatch({ type: GET_TRANSACTION_RESET });
    };

    const setCurrencySelectedHandler = (value) => {
        setCurrencySelected(value);
        setCurrencySelectOpened(false);
    };
    useEffect(()=>{
        if(!isTodaySunday()&&variables.withdrawModalOpened){
         
            
            setOnlyModayOpened(true);
            setWithdrawModalOpened(false);
        }
    },[variables]);

   console.log(variables);
   

    if (!variables.withdrawModalOpened) {
        return <></>;
    }
    
    if (success || Object.keys(transaction).length !== 0) {
        return (
            <WithdrawRequested
                onOutsideClickHandler={onOutsideClickHandler}
                amount={amount}
                wallet={wallet}
                getReceiveAmount={getReceiveAmount}
                commisionUSD={commisionUSD}
                currencySelected={currencySelected}
                currencies={currencies}
            />
        );
    }

    return (
        <WithdrawDefault
            success={success}
            onOutsideClickHandler={onOutsideClickHandler}
            amount={amount}
            wallet={wallet}
            setWallet={setWallet}
            loadingTariff={loadingTariff}
            setAmount={setAmount}
            tariff={tariff}
            getReceiveAmount={getReceiveAmount}
            withdrawHandler={withdrawHandler}
            noCommision={noCommision}
            balance={balance}
            setCurrencySelectOpened={setCurrencySelectOpened}
            currencySelectOpened={currencySelectOpened}
            setCurrencySelectedHandler={setCurrencySelectedHandler}
            currencySelected={currencySelected}
            currencies={currencies}
            personalBalance={personalBalance}
            earned={earned}
            subType={subType}
            setSubType={setSubType}
        />
    );
});

export default WithdrawModal;

function getReceiveAmount(amount, noCommision, tariffCommission,subType) {
    let underCommision = noCommision;


    if(subType=="Deposit"){
        return amount;
    }
    else if(subType=="Income"){
        return amount - (amount * tariffCommission/100);
    }

    // if (amount - noCommision <= 0) {
    //     if (typeof amount == "string") {
    //         amount = parseFloat(amount);
    //     }

    //     if (isNaN(amount)) {
    //         return "0.00";
    //     }
    //     return (amount - amount * (tariffCommission / 100)).toFixed(2);
    // } else if (amount - noCommision > 0) {
    //     return (
    //         underCommision -
    //         underCommision * (tariffCommission / 100) +
    //         (amount - noCommision)
    //     ).toFixed(2);
    // }
    // return "0.00";
    // if(amount-noCommision<=0){

    //     console.log("com ");
    //     if(typeof amount =="string"){
    //         amount=parseFloat(amount);
    //     }
    //     let underCommision=Math.abs( amount-noCommision);
    //     underCommision*=(tariffCommission/100);

    //     return (amount - underCommision).toFixed(2);

    // }else if(amount-noCommision>0){

    //     console.log("nocom");

    //     return amount;
    // }
}

function isTodaySunday() {
    // Создаем объект Date для текущей даты
    const today = new Date();

    // Проверяем, является ли день недели воскресеньем (0 в методе getDay() означает воскресенье)
    return today.getDay() === 0;
}