export const GET_CURRENCIES_REQUEST = "GET_CURRENCIES_REQUEST";
export const GET_CURRENCIES_SUCCESS = "GET_CURRENCIES_SUCCESS";
export const GET_CURRENCIES_FAIL = "GET_CURRENCIES_FAIL";
export const GET_CURRENCIES_RESET = "GET_CURRENCIES_RESET";

export const GET_WALLETS_REQUEST = "GET_WALLETS_REQUEST";
export const GET_WALLETS_SUCCESS = "GET_WALLETS_SUCCESS";
export const GET_WALLETS_FAIL = "GET_WALLETS_FAIL";
export const GET_WALLETS_RESET = "GET_WALLETS_RESET";

export const CREATE_DEPOSIT_REQUEST = "CREATE_DEPOSIT_REQUEST";
export const CREATE_DEPOSIT_SUCCESS = "CREATE_DEPOSIT_SUCCESS";
export const CREATE_DEPOSIT_FAIL = "CREATE_DEPOSIT_FAIL";
export const CREATE_DEPOSIT_RESET = "CREATE_DEPOSIT_RESET";


export const GET_TRANSACTION_REQUEST = "GET_TRANSACTION_REQUEST";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_FAIL = "GET_TRANSACTION_FAIL";
export const GET_TRANSACTION_RESET = "GET_TRANSACTION_RESET";

export const REF_TRANSFER_REQUEST = "REF_TRANSFER_REQUEST";
export const REF_TRANSFER_SUCCESS = "REF_TRANSFER_SUCCESS";
export const REF_TRANSFER_FAIL = "REF_TRANSFER_FAIL";
export const REF_TRANSFER_RESET = "REF_TRANSFER_RESET";

export const CREATE_WITHDRAW_REQUEST = "CREATE_WITHDRAW_REQUEST";
export const CREATE_WITHDRAW_SUCCESS = "CREATE_WITHDRAW_SUCCESS";
export const CREATE_WITHDRAW_FAIL = "CREATE_WITHDRAW_FAIL";
export const CREATE_WITHDRAW_RESET = "CREATE_WITHDRAW_RESET";


export const CONFIRM_DEPOSIT_REQUEST = "CONFIRM_DEPOSIT_REQUEST";
export const CONFIRM_DEPOSIT_SUCCESS = "CONFIRM_DEPOSIT_SUCCESS";
export const CONFIRM_DEPOSIT_FAIL = "CONFIRM_DEPOSIT_FAIL";
export const CONFIRM_DEPOSIT_RESET = "CONFIRM_DEPOSIT_RESET";


export const GET_COMISSION_WITHDRAW_REQUEST = "GET_COMISSION_WITHDRAW_REQUEST";
export const GET_COMISSION_WITHDRAW_SUCCESS = "GET_COMISSION_WITHDRAW_SUCCESS";
export const GET_COMISSION_WITHDRAW_FAIL = "GET_COMISSION_WITHDRAW_FAIL";
export const GET_COMISSION_WITHDRAW_RESET = "GET_COMISSION_WITHDRAW_RESET";