import React, { useEffect, useState } from "react";
import mobxStore from "../../mobx/mobxStore";
import "../../css/pages/profile.css";
import { useDispatch, useSelector } from "react-redux";
import {
    changePassword,
    deleteImage,
    getUserDetails,
    getUserTariff,
    updateUserProfile,
} from "../../Redux/Actions/userActions";
import { clientLink, serverLink } from "../../App";
import { getImageFromServer } from "../../helpers/getImageFromServer";
import OutsideAlerter from "../../helpers/outsideAlerter";
import { getNames } from "country-list";
import { uniqid } from "../../helpers/uniqid";
import { toast } from "react-toastify";
import Toast from "../../component/Toast/toast";
import useCopy from "use-copy";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { USER_IMAGE_DELETE_RESET, USER_PASSWORD_CHANGE_RESET } from "../../Redux/Constans/userContains";
const Profile = (props) => {
    const { toastId } = props;
    const dispatch = useDispatch();
    const coutries = getNames();
    const userDetails = useSelector((state) => state.userDetails);
    const updateProfile = useSelector((state) => state.updateProfile);
    const passwordReset = useSelector((state) => state.passwordReset);
    const {
        loading: loadingPasswordReset,
        change,
        error: errorPasswordRest,
    } = passwordReset;
    const { setDeleteAccountModalOpened } = mobxStore;
    const { user, loading } = userDetails;
    const [selectCountryOpened, setSelectCountryOpened] = useState(false);
    const userTariff = useSelector((state) => state.userTariff);
    const { loading: loadingTariff, tariff } = userTariff;

    const resetImage = useSelector((state) => state.resetImage);
    const { deleted, loading: loadingReset, error: errorReset } = resetImage;

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPasswordNew,setShowPasswordNew]=useState(false);
    const [showPasswordConfirm,setShowPasswordConfirm]=useState(false);


    const [state, setState] = useState({
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        countryOfResidence: "",
        userAvatar: "",
    });
    const [userReferralCode, setUserReferralCode] = useState("");
    const [userRefCopied, copyRef, setUserCopy] = useCopy(
        getUserRefferalCodeLink(userReferralCode)
    );
    useEffect(() => {
        dispatch(getUserDetails());
    }, [dispatch]);
    useEffect(() => {
        dispatch(getUserTariff());
    }, [dispatch]);
    useEffect(() => {
        if (!loading && user) {
            setState({
                username: user.username,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                dateOfBirth: user.dateOfBirth,
                countryOfResidence: user.countryOfResidence,
                userAvatar: user.userAvatar,
            });
            setUserReferralCode(user.referralCode);
        }
    }, [userDetails]);

    const changeState = (e, field) => {
        setState({ ...state, [field]: e.target.value });
        setSelectCountryOpened(false);
    };
    const changeCountry = (country) => {
        setState({ ...state, countryOfResidence: country });
    };
    const changeImage = (e) => {
        setState({ ...state, userAvatar: e });
    };
    const changeProfileAvatar = (e) => {
        e.preventDefault();

        //changeImage(URL.createObjectURL(e.target.files[0]));
        changeImage(e.target.files[0]);
    };

    const saveChangesHandler = () => {
        const formData = new FormData();

        if (state.userAvatar && typeof state.userAvatar !== "string") {
            formData.append("avatar", state.userAvatar);
        }

        for (const [key, value] of Object.entries(state)) {
            if (key !== "userAvatar") {
                formData.append(key, value);
            }
        }

        dispatch(updateUserProfile(formData));
    };
    useEffect(() => {
        if (updateProfile.success) {
            dispatch(getUserDetails());
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success(updateProfile.userInfo.message);
            }
        } else if (updateProfile.error) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(updateProfile.error);
            }
        }
    }, [updateProfile]);

    useEffect(() => {
      
        
        if (deleted) {
     
            
            dispatch(getUserDetails());
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success(
                    "The photo was successfully deleted"
                );
            }
            dispatch({type:USER_IMAGE_DELETE_RESET});
        } else if (errorReset) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(
                    "Internal server error. Try again later or write to the manager"
                );
            }
            dispatch({type:USER_IMAGE_DELETE_RESET});
        }
    }, [resetImage]);
 
    
    const refCopyHandler = () => {
        setUserCopy(true);

        setTimeout(() => {
            setUserCopy(false);
        }, 3000);

        copyRef();
    };

    const changePasswordHandler = () => {
        const validate = validatePassword(newPassword);
        if (!validate.success) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(validate.message);
            }
        } else if (newPassword == confirmPassword && validate.success) {
            dispatch(changePassword({ newPassword }));
        } else if (newPassword !== confirmPassword) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Passwords must match.");
            }
        }
    };
   

    const deleteImageHandler = () => {
        dispatch(deleteImage());
    };

    useEffect(() => {
        if (!loading && typeof loading !== "undefined") {
            if (change) {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(
                        "The password was reset successfully."
                    );
                }
                dispatch({ type: USER_PASSWORD_CHANGE_RESET });
            } else if (errorPasswordRest) {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(errorPasswordRest);
                }
                dispatch({ type: USER_PASSWORD_CHANGE_RESET });
            }
        }
    }, [passwordReset, dispatch]);

   

    return (
        <div className="profile profile-v1" style={{ paddingBottom: "60px" }}>
            <div className="container container--dashboard">
                <div className="profile__title">
                    <div className="profile__title-text">
                        <h3
                            className="profile__title-text-1 aos-init aos-animate"
                            data-aos="fade-up"
                        >
                            Profile and Settings
                        </h3>
                        <p
                            className="fd-md profile__title-text-2 aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay={100}
                        >
                            Setup your profile
                        </p>
                    </div>{" "}
                    <p
                        className={loadingTariff ? "blur-modal" : ""}
                        style={{
                            textAlign: "right",
                            fontSize: "20px",
                        }}
                    >
                        <i class="pulse green"></i>
                        <HashLink to="/#choose-plan">
                            {" "}
                            <span style={{ color: "#ffffff" }}>
                                Current Tariff —
                            </span>{" "}
                            {!loadingTariff ? tariff.name : "Standart"}
                        </HashLink>
                    </p>
                </div>
                <div className="profile__container">
                    <div className="profile__content">
                        <div className="profile__contact-info">
                            <div className="contact-info__photo-profile">
                                <div
                                    className="photo-profile__group aos-init aos-animate"
                                    data-aos="fade-right"
                                >
                                    <img
                                        src={
                                            !loading && state.userAvatar
                                                ? state.userAvatar &&
                                                  typeof state.userAvatar ===
                                                      "string" &&
                                                  state.userAvatar.length > 4
                                                    ? getImageFromServer(
                                                          state.userAvatar
                                                      )
                                                    : URL.createObjectURL(
                                                          state.userAvatar
                                                      )
                                                : "/assets/media/images/avatars/profile-image.png"
                                        }
                                        alt=""
                                        className="photo-profile__group-photo"
                                    />
                                    <div className="photo-profile__group-text">
                                        <p className="fd-sm fd-sm--bold photo-profile__group-text-name">
                                            {!loading && user.username}
                                        </p>
                                        <p className="fb-sm photo-profile__group-text-email">
                                            {!loading && user.email}
                                        </p>
                                    </div>
                                </div>
                                <div className="photo-profile__cta">
                                    <input
                                        type="file"
                                        id="file"
                                        style={{ display: "none" }}
                                        onChange={changeProfileAvatar}
                                    />
                                    <button
                                        className="btn btn-primary btn-sm btn-pill aos-init aos-animate"
                                        data-aos="fade-left"
                                        data-aos-delay={50}
                                        onClick={() =>
                                            document
                                                .getElementById("file")
                                                .click()
                                        }
                                    >
                                        Change Photo Profile
                                    </button>
                                    <button
                                        className="btn btn-secondary btn-sm aos-init aos-animate"
                                        data-aos="fade-left"
                                        data-aos-delay={100}
                                        onClick={() => deleteImageHandler()}
                                    >
                                        Delete
                                    </button>
                                    <Link
                                        className="btn btn-primary btn-sm aos-init aos-animate"
                                        data-aos="fade-left"
                                        data-aos-delay={100}
                                        to="/logout"
                                        style={{
                                            background: "#FA2256",
                                            borderColor: "#FA2256",
                                        }}
                                    >
                                        Logout
                                    </Link>
                                </div>
                            </div>
                            <div className="contact-info__name">
                                <div className="contact-info__name-form">
                                    <p className="fb-regular fb-regular--bold contact-info__name-form-text">
                                        Username
                                    </p>
                                    <div
                                        className="d-block mini-form-sm__box contact-info__name-form-input aos-init aos-animate"
                                        data-aos="fade-up"
                                        data-aos-delay={50}
                                    >
                                        <form className="mini-form">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm mini-form__input"
                                                placeholder="John Doe"
                                                autoComplete="off"
                                                value={state.username}
                                                onChange={(e) =>
                                                    changeState(e, "username")
                                                }
                                            />
                                        </form>
                                    </div>
                                </div>
                                <div className="contact-info__name-form">
                                    <p className="fb-regular fb-regular--bold contact-info__name-form-text">
                                        Email
                                    </p>
                                    <div
                                        className="d-block mini-form-sm__box contact-info__name-form-input aos-init aos-animate"
                                        data-aos="fade-up"
                                        data-aos-delay={50}
                                    >
                                        <form className="mini-form">
                                            <input
                                                type="email"
                                                className="form-control form-control-sm mini-form__input"
                                                placeholder="johndoe123@gmail.com"
                                                autoComplete="off"
                                                value={state.email}
                                                onChange={(e) =>
                                                    changeState(e, "email")
                                                }
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile__personal-info">
                            <div className="personal-info__name-form">
                                <p className="fb-regular fb-regular--bold personal-info__name-form-text">
                                    First name
                                </p>
                                <div
                                    className="d-block mini-form-sm__box personal-info__name-form-input aos-init aos-animate"
                                    data-aos="fade-up"
                                    data-aos-delay={50}
                                >
                                    <form className="mini-form">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm mini-form__input"
                                            placeholder="John"
                                            autoComplete="off"
                                            value={state.firstName}
                                            onChange={(e) =>
                                                changeState(e, "firstName")
                                            }
                                        />
                                    </form>
                                </div>
                            </div>
                            <div className="personal-info__name-form">
                                <p className="fb-regular fb-regular--bold personal-info__name-form-text">
                                    Last name
                                </p>
                                <div
                                    className="d-block mini-form-sm__box personal-info__name-form-input aos-init aos-animate"
                                    data-aos="fade-up"
                                    data-aos-delay={50}
                                >
                                    <form className="mini-form">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm mini-form__input"
                                            placeholder="Doe"
                                            autoComplete="off"
                                            value={state.lastName}
                                            onChange={(e) =>
                                                changeState(e, "lastName")
                                            }
                                        />
                                    </form>
                                </div>
                            </div>
                            <div className="personal-info__name-form personal-info__name-form--date">
                                <p className="fb-regular fb-regular--bold personal-info__name-form-text">
                                    Date of birth
                                </p>
                                <div
                                    className="d-block mini-form-sm__box personal-info__name-form-input aos-init aos-animate"
                                    data-aos="fade-up"
                                    data-aos-delay={50}
                                >
                                    <form className="mini-form">
                                        <input
                                            type="text"
                                            id="datepicker-1"
                                            className="form-control form-control-sm mini-form__input"
                                            placeholder="10 / 21 / 2022"
                                            autoComplete="off"
                                            value={state.dateOfBirth}
                                            onChange={(e) =>
                                                changeState(e, "dateOfBirth")
                                            }
                                        />
                                    </form>
                                    <img
                                        className="personal-info__name-form-input-icon"
                                        src="/assets/media/images/icons/calendar.svg"
                                        alt=""
                                    />
                                </div>
                            </div>

                            <OutsideAlerter
                                className="personal-info__name-form personal-info__name-form--country"
                                style={{ zIndex: 1 }}
                                onOutsideClick={() => {
                                    setSelectCountryOpened(false);
                                }}
                            >
                                <p className="fb-regular fb-regular--bold personal-info__name-form-text">
                                    Country of residence
                                </p>
                                <div
                                    className="d-block aos-init aos-animate"
                                    data-aos="fade-up"
                                    data-aos-delay={50}
                                >
                                    <div
                                        className="forms-group forms-select js-forms-select"
                                        id="custom-select-form-1"
                                        onClick={() =>
                                            setSelectCountryOpened(
                                                !selectCountryOpened
                                            )
                                        }
                                    >
                                        <div
                                            className="forms-group__items selected"
                                            data-selected="indonesia"
                                        >
                                            <p className="fb-regular fg-items__value">
                                                {state.countryOfResidence}
                                            </p>
                                        </div>
                                        <img
                                            className="forms-select__arrow-down"
                                            src="/assets/media/images/icons/arrow-down.svg"
                                            alt=""
                                        />
                                        <div
                                            className={`forms-group__dropdown js-forms-group__dropdown ${
                                                selectCountryOpened
                                                    ? "is-open"
                                                    : ""
                                            }`}
                                            style={{
                                                maxHeight: "200px",
                                                overflowY: "auto",
                                            }}
                                        >
                                            {coutries.map((country) => (
                                                <div
                                                    className="forms-group__items active"
                                                    data-selected={country}
                                                    key={uniqid()}
                                                    onClick={() =>
                                                        changeCountry(
                                                            country,
                                                            "countryOfResidence"
                                                        )
                                                    }
                                                >
                                                    <p className="fb-regular fg-items__value">
                                                        {country}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </OutsideAlerter>
                            <div className="personal-info__name-form personal-info__name-form--date">
                                <p className="fb-regular fb-regular--bold personal-info__name-form-text">
                                    Referral Code
                                </p>
                                <div
                                    className="d-block mini-form-sm__box personal-info__name-form-input aos-init aos-animate"
                                    data-aos="fade-up"
                                    data-aos-delay={50}
                                    onClick={refCopyHandler}
                                >
                                    <input
                                        type="text"
                                        id="datepicker-1"
                                        className="form-control form-control-sm mini-form__input"
                                        placeholder={clientLink}
                                        autoComplete="off"
                                        value={getUserRefferalCodeLink(
                                            userReferralCode
                                        )}
                                    />
                                    {userRefCopied ? (
                                        <img
                                            className="personal-info__name-form-input-icon"
                                            src="/images/green-check-mark.svg"
                                            alt=""
                                            style={{
                                                position: "absolute",
                                                transform: "translateY(-50%)",
                                                right: "1.75rem",
                                                top: "50%",
                                            }}
                                            width={20}
                                        />
                                    ) : (
                                        <img
                                            className="personal-info__name-form-input-icon"
                                            src="/assets/media/images/icons/copy.svg"
                                            alt=""
                                            style={{
                                                position: "absolute",
                                                transform: "translateY(-50%)",
                                                right: "1.75rem",
                                                top: "50%",
                                            }}
                                            width={20}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <button
                            className="btn btn-primary btn-lg btn-pill profile__save-btn aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay={100}
                            onClick={saveChangesHandler}
                        >
                            Save Changes
                        </button>

                        <div className="profile__personal-info">
                            <div className="personal-info__name-form">
                                <p className="fb-regular fb-regular--bold personal-info__name-form-text">
                                    New Password
                                </p>
                                <div
                                    className="d-block mini-form-sm__box personal-info__name-form-input aos-init aos-animate"
                                    data-aos="fade-up"
                                    data-aos-delay={50}
                                >
                                    <form className="mini-form forms-group">
                                        <input
                                            type={showPasswordNew?"text":"password"}
                                            className="form-control form-control-sm mini-form__input"
                                            autoComplete="off"
                                            value={newPassword}
                                            onChange={(e) =>
                                                setNewPassword(e.target.value)
                                            }
                                        />
                                          <span
                            className="form-group__password-toggle js-form-group__password-toggle"
                            data-password="password"
                            onClick={() => setShowPasswordNew(!showPasswordNew)}
                        />
                                    </form>
                                </div>
                            </div>

                            <div className="personal-info__name-form">
                                <p className="fb-regular fb-regular--bold personal-info__name-form-text">
                                    Confirm Password
                                </p>
                                <div
                                    className="d-block mini-form-sm__box personal-info__name-form-input aos-init aos-animate"
                                    data-aos="fade-up"
                                    data-aos-delay={50}
                                >
                                    <form className="mini-form forms-group">
                                        <input
                                            type={showPasswordConfirm?"text":"password"}
                                            className="form-control form-control-sm mini-form__input"
                                            name="update-password"
                                            autoComplete="off"
                                            value={confirmPassword}
                                            onChange={(e) =>
                                                setConfirmPassword(
                                                    e.target.value
                                                )
                                            }
                                        />
                                           <span
                            className="form-group__password-toggle js-form-group__password-toggle"
                            data-password="password"
                            onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                        />
                                    </form>
                                </div>
                            </div>
                        </div>
                        <button
                            className="btn btn-primary btn-lg btn-pill profile__save-btn aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay={100}
                            onClick={changePasswordHandler}
                        >
                            Change password
                        </button>
                    
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;

function getUserRefferalCodeLink(referralCode) {
    return clientLink + "/sign-up?referral=" + referralCode;
}

function validatePassword(password) {
    // Регулярное выражение для проверки допустимых символов
    const validPattern = /^[A-Za-z0-9!@#$%^&*(),.?":{}|<>]*$/;

    // Проверка на наличие пробелов
    if (/\s/.test(password)) {
        return {
            success: false,
            message: "The password must not contain spaces.",
        };
    }

    // Проверка на соответствие допустимым символам
    if (!validPattern.test(password)) {
        return {
            success: false,
            message:
                "The password can contain only Latin letters, numbers and special characters.",
        };
    }
    if (password.length < 8) {
        return {
            success: false,
            message: "Password must be at least 8 characters.",
        };
    }
    return { success: true };
}
