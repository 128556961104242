// import "./assets/scss/abstracts/_mixins.scss";
// import "./assets/scss/abstracts/_variables.scss";
// import "./assets/scss/abstracts/_fonts.scss";
// import "./assets/scss/styles.scss";

// import "./assets/scss/base/_animation.scss";
// import "./assets/scss/base/_extends.scss";
// import "./assets/scss/base/_reset.scss";
// import "./assets/scss/base/_typography.scss";

import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./layout/home/home";
import Dashboard from "./layout/dashboard/dashboard";
import Header from "./component/header";
import "./css/styles.css";
import "./css/pages/landing-page_new.css";
//import Exchange from "./layout/exchange/exchange";
import "aos/dist/aos.css";
import "aos/dist/aos";
import Aos from "aos";
import Wallet from "./layout/wallet/wallet";
import Market from "./layout/market/market";
import Profile from "./layout/profile/profile";
import SignIn from "./component/signin";
import SignUp from "./component/signup";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./privateRoute";
import Logout from "./helpers/logout";
import "./index.css";
import DepositModal from "./component/deposit";
import WithdrawModal from "./component/withdraw";
import PrivacyPolicy from "./layout/privacypolicy";
import TermsOfUse from "./layout/termsofuse";
import Exchange from "./layout/exchange/exchange";
import Toast from "./component/Toast/toast";
import TransferModal from "./component/transfer";
import { toast } from "react-toastify";
import DeleteAccountModal from "./layout/confirmmodals/deleteAccountModal";
import HeaderHome from "./layout/home/elements/headerHome";
import ForgotPassword from "./component/forgotPassword";
import TriangleModal from "./layout/exchange/triangleModal";
import OnlyMonday from "./component/withdraw/onlyMonday";
const App = () => {
    const toastId = React.useRef(null);
    useEffect(function () {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <BrowserRouter>
            <Toast />
            <OnlyMonday/>
            <DepositModal toastId={toastId} toast={toast} />
            <WithdrawModal toastId={toastId} toast={toast} />
            <TransferModal toastId={toastId} toast={toast} />
            <DeleteAccountModal toastId={toastId} toast={toast} />
            <TriangleModal/>
            <Routes>
                <Route index element={<Home />} />
                {/* <Route path="dashboard" element={<Dashboard/>}/> */}
                <Route
                    path="dashboard"
                    element={
                        <PrivateRoute>
                             <HeaderHome/>
                            <Dashboard />
                        </PrivateRoute>
                    }
                />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route
                    path="wallet"
                    element={
                        <PrivateRoute>
                             <HeaderHome/>
                            <Wallet toastId={toastId} toast={toast} />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="statistics"
                    element={
                        <PrivateRoute>
                             <HeaderHome/>
                            <Exchange />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="profile"
                    element={
                        <PrivateRoute>
                             <HeaderHome/>
                            <Profile toastId={toastId} />
                        </PrivateRoute>
                    }
                />
                <Route path="terms" element={<TermsOfUse />} />
                <Route path="sign-in" element={<SignIn />} />
                <Route path="forgot-password" element={<ForgotPassword/>}/>
                <Route path="sign-up" element={<SignUp />} />
                <Route path="logout" element={<Logout />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
//export const serverLink = "http://localhost:6111";
export const serverLink ="https://api.p2p-arbitraz.com";
//export const clientLink = "http://localhost:3000";
export const clientLink = "https://p2p-arbitraz.com";
export const Toastobjects = {
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    autoClose: 2000,
};