import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Reviews=()=>{
        const slidesToShow=3;
        
        const [slideIndex,setSlideIndex]=useState(0);
        let sliderRef=useRef(null);
        const settings={
                className:"centermode",
                centerMode: true,
                infinite: true,
                centerPadding: "60px",
                slidesToShow,
                speed: 500,
                arrows:false,
                autoplaySpeed:5000,
                autoplay:true,
                cssEase: "linear",
                beforeChange: (current, next) => setSlideIndex(next),
                responsive: [
                        {
                          breakpoint: 1100,
                          settings: {
                            slidesToShow: 2,
                        //     slidesToScroll: 3,
                        //     infinite: true,
                        //     dots: true
                          }
                        },
                        {
                          breakpoint: 870,
                          settings: {
                            slidesToShow: 1,
                            centerPadding: "0",
                        //     slidesToScroll: 2,
                        //     initialSlide: 2
                          }
                        },
                      
                      ]

        }       


        return (    <section className="reviews" id="reviews" style={{scrollMarginTop:"100px"}}>
                <img src="/images/bg-6.svg" className="bg" draggable={false}/>
                <div className="home_container">
                    <h2 className="home-title">
                        <span className="text-blue">Reviews</span> of our
                        customers
                    </h2>
                    <Slider {...settings} ref={slider=>{
                                sliderRef=slider;
                        }}>

                     {reviews.map(review=> <div className="review-item">
                            <div className="review-header">
                                <img src={`/images/review/${review.image}`} />
                                <div className="review-title">
                                    <p className="review-name">{review.name}</p>
                                    <p className="review-undertitle">
                                        {review.undertitle}
                                    </p>
                                </div>
                            </div>
    
                            <p className="review-text">
                              {review.reviewText}
                            </p>
                        </div>)}
                       

                                </Slider>
                   
                      
                    
                                <div className="slider-bar">
                        <div className="slider-progress" style={{width:1/reviews.length*100+"%",marginLeft:(slideIndex/reviews.length)*100+"%"}}></div>
                    </div>
                </div>
            </section>)       
}


export default Reviews;



const reviews=[
        {
                image:"review-luca.jpg",
                name:"📈 Luca, 30 years old",
                undertitle:"Italy",
                reviewText:`Luca joined us just 2 months ago, investing $3,000 in the "Invest" package. In this short time, he managed to earn $5,800 using our automated bot. Luca plans to buy a new car thanks to his successes!`
        }, {
                image:"review-emily.jpg",
                name:"💼 Emily, 27 years old",
                undertitle:"Germany",
                reviewText:`Emily decided to try staking with an initial investment of $500 in the "Standart" package. In just 1 month, she earned a profit of $650! Emily is impressed with the platform and recommends it to her friends.`
        }, {
                image:"review-olivie.jpg",
                name:"🎉 Olivia and Philip",
                undertitle:"a young couple from France",
                reviewText:`Olivia and Philip invested $17,000 in the "Prime" package and earned $24,605 in just one month. They are already planning their trip to Spain with the funds they received!`
        }, {
                image:"review-max.jpg",
                name:"🎊 Max, 35 years old",
                undertitle:"Sweden",
                reviewText:`Max started with an investment of $1,500 in the "Silver" package. In 2 months, he managed to earn $2,700! He plans to spend part of his profit on a trip to Italy.`
        },
        {
                image:"review-thomas.jpg",
                name:"🏆 Thomas, 42 years old",
                undertitle:"Austria",
                reviewText:`Thomas decided to invest $25,000 in staking, and in one month, his profit amounted to $33,000! He has already arranged to view a house he has long dreamed of buying.`
        },
        {
                image:"review-anna.jpg",
                name:"💰 Anna, 29 years old",
                undertitle:"Netherlands",
                reviewText:`Anna invested $5,000 in the "Invest" package. In just 6 weeks, she earned $9,800 and is already planning new investments! Anna shares her experience with friends to help them succeed as well.`
        },


]