import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer>
            <div className="home_container footer-new">
                <div className="footer-platform_info">
                    <span className="text-blue platform_name" onClick={()=>window.scroll(0,0)} style={{cursor:"pointer"}}>
                        P2P ARBITRAZ
                    </span>
                    <p>
                        Ready to take the first step into the world of crypto
                        investments?
                    </p>
                    <Link to="/sign-up" className="home_btn join_now">
                        Join now
                    </Link>
                </div>

                <div className="footer-platform_links">
                    <div className="footer-platform_list">
                        <a href="#about-us">About us</a>
                        <a href="#how-it-works">How it works</a>
                        <a href="#choose-plan">Tariff plans</a>
                        <a href="#reviews">Reviews</a>
                        <a href="https://t.me/emma_clark_p2p">Contacts</a>
                    </div>
                    <div className="footer-platform_social">
                        <a href="https://t.me/+RZlWsvoiY303YWRi">
                            <img src="/images/facebook.svg" />
                        </a>
                        <a href="https://t.me/+RZlWsvoiY303YWRi">
                            <img src="/images/instagram.svg" />
                        </a>
                        <a href="https://t.me/+RZlWsvoiY303YWRi">
                            <img src="/images/linkedin.svg" />
                        </a>
                        <a href="https://t.me/+RZlWsvoiY303YWRi">
                            <img src="/images/telegram.svg" />
                        </a>
                    </div>
                </div>
                <div className="footer-platform_terms">
                    <div className="footer-platform_terms-link">
                        <Link to="/privacy-policy">Privacy Policy</Link>
                        <Link to="/terms">Terms of use</Link>
                    </div>
                    <div>
                        <Link to="/sign-up" className="home_btn join_now">
                            Join now
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
