import { observer } from "mobx-react-lite";
import React from "react";
import mobxStore from "../../mobx/mobxStore";
import OutsideAlerter from "../../helpers/outsideAlerter";
const TriangleWindow = observer((props) => {
    const { index, isTriangle } = props;
    const { variables, openTriangle, closeTriangle } = mobxStore;
    const { openedTriangleWindow } = variables;
    console.log(openedTriangleWindow);
    
    return (
        <td
            // onMouseOut={
            //     closeTriangle
            // }
            className={"td-triangle "+ (openedTriangleWindow == index ? "triangle_open" : "")}
            style={{
                textAlign: "center",
            }}
        >
            <div className="fb-regular ">
                <span>
                    {isTriangle ? (
                        <img
                            src="/question-mark.svg"
                            width="24px"
                            onClick={() => openTriangle(index)}
                        />
                    ) : (
                        ""
                    )}
                </span>
               
                
            </div> 
        </td>
    );
});

export default TriangleWindow;
