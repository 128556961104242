import { observer } from "mobx-react-lite";
import React from "react";
import mobxStore from "../../mobx/mobxStore";
import OutsideAlerter from "../../helpers/outsideAlerter";

const TriangleModal=observer(()=>{
        const {openTriangle,closeTriangle,variables}=mobxStore;


        if(!variables.openedTriangleWindow){
return <></>;
        }

                return     <div className="modal-window" style={{zIndex:"2"}}>
                <OutsideAlerter
                    data-aos="fade-up"
                    className="advance-card advance-card--type-1-v1 aos-init aos-animate modal-main"
                    onOutsideClick={() => closeTriangle()}
                >
                  
                    <p
                        className="advance-card__forms-currency__title"
                        style={{ color: "#ffffff" }}
                    >
                        This is a triangle trade. This exchange is performed
                        automatically by the server. All exchanges are carried
                        out in the direction of the USDT. For example, SOL-USDT,
                        USDT-BTC, BTC-SOL. Due to slight differences in exchange
                        rates, the exchange is circled.
                    </p>
                   
                    <button
                        className="btn btn-primary  advance-card__button btn-sm btn-pill"
                        
                        onClick={() => closeTriangle()}
                        style={{marginTop:"2rem"}}
                    >
                        OK
                    </button>
                  
                </OutsideAlerter>
            </div>;
});

export default TriangleModal;