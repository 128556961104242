import React, { useState } from "react";

const AccordionItem=({title,content})=>{
        const [isActive, setIsActive] = useState(false);

        return (
          <div className={`accordion-item_new ${isActive?"active":""}`}>
            <button
              className={`accordion_new ${isActive ? 'active' : ''}`}
              onClick={() => setIsActive(!isActive)}
            >
              {title}
              <span className="accordion-symbol">
                {/* {isActive ? '−' : '+'} */}
                <img src="/images/arrow-blue.svg"/>
              </span>
            </button>
            <div
              className="panel"
              style={{ maxHeight: isActive ? '900px' : '0px' }}
            >
              <p>{content}</p>
            </div>
          </div>
        );
}

export default AccordionItem;