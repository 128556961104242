import React, { Fragment, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { getWalletDonutChart } from "../dashboard/donutChart";
import AccordionItem from "./elements/accordionItem";
import { uniqid } from "../../helpers/uniqid";
import Footer from "./elements/footer";
import "./elements/customScroll.css";
import HeaderHome from "./elements/headerHome";
import ChoosePlan from "./elements/choosePlan";
import Reviews from "./elements/reviews";
import { useLocation } from "react-router";

// export const Home = () => {
//     const navigate = useNavigate();
//     return (
//         <Fragment>
//             <section className="hero">
//                 <div className="hero__background hero__background--gradient">
//                     <img
//                         className="hero__gradient-bg"
//                         src="/assets/media/images/landing-page-gradient-bg.svg"
//                         alt="Background Gradient Hero"
//                     />
//                 </div>
//                 <div className="hero__background hero__background--pattern">
//                     <img
//                         className="hero__pattern-bg"
//                         src="/assets/media/images/landing-page-pattern.svg"
//                         alt="Background Pattern Hero"
//                     />
//                 </div>
//                 <div className="hero__background--mobile hero__background--gradient">
//                     <img
//                         className="hero__gradient-bg"
//                         src="/assets/media/images/mobile-responsive/mobile-landing-page-hero-gradient.png"
//                         alt="Background Gradient Hero"
//                     />
//                 </div>
//                 <div className="hero__background--mobile hero__background--pattern">
//                     <img
//                         className="hero__pattern-bg"
//                         src="/assets/media/images/mobile-responsive/mobile-landing-page-hero-pattern.png"
//                         alt="Background Pattern Hero"
//                     />
//                 </div>
//                 <div className="container">
//                     <div
//                         className="hero__content aos-init aos-animate"
//                         data-aos="fade-up"
//                     >
//                         <h1 className="hero__title mx-auto">
//                         Что такое криптовалютный арбитраж?
//                         </h1>
//                         <p className="fb-lg mx-auto hero__subtitle">
//                         Криптовалютный арбитраж - это стратегия торговли, при которой вы можете заработать на разнице в ценах на один и тот же актив на разных биржах. Наше программное обеспечение автоматически анализирует рынок и проводит сделки за вас, обеспечивая максимальную прибыль.
//                         </p>
//                         <button className="btn btn-primary btn-lg btn-pill mx-auto hero__button" onClick={()=>navigate("/sign-up")}>
//                             Начать
//                         </button>
//                     </div>
//                 </div>
//             </section>
//             <GridGraph />
//             {/* Start::Feature */}
//             <Feature />
//             <Assets />
//             <HowWorks />
//             <HowMany/>
//             <OurTeam />
//             <HowToStart />
//             <FAQ />
//         <Calculator/>
//             <Footer />
//         </Fragment>
//     );
// };

export const Home = () => {
    const [donutChart, setDonutChart] = useState();
    const {hash}=useLocation();
    useEffect(() => {
        const donutChartInfo = getWalletDonutChart(
            [0.54, 0.25, 0.21],
            ["#3E57FF", "#848CFF", "#9AB3FF"]
        );

        setDonutChart(donutChartInfo);
    }, []);

    useEffect(() => {
        document.body.style.backgroundColor = "#040404";

        return ()=>{
            document.body.style.backgroundColor="";
        }

    }, []);
   
    
    
    useEffect(() => {
        // Проверяем, есть ли якорь в URL и если да, скроллим к нужному элементу
      
            const element = document.getElementById(hash.replace("#",""));
           
            if (element) {
                
                
                element.scrollIntoView({ behavior: 'smooth' });
            }
        
    }, [hash]);


    return (
        <Fragment>
            <HeaderHome/>
        <div className="custom-scrollbars__content home-page">
            
             
                <img
                    src="/images/bg-1.svg"
                    className="main-bg"
                    draggable={false}
                />
                <section className="hero home_container main-graph">
                    <div className="hero_text">
                        <h1>
                            Earn passive income with{" "}
                            <span className="text-blue">P2P ARBITRAZ</span>
                        </h1>
                        <p>
                            Join our automated P2P arbitrage bot and earn weekly
                            income from the price difference between exchanges
                        </p>

                        <Link className="home_btn get-started_btn" to="/sign-up">
                            Get started now
                        </Link>
                    </div>

                    <div className="hero_block crypto-graph">
                        <div className="crypto-graph_element">
                            <span className="crypto-asset text-blue">BTC</span>
                            <span className="crypto-profit">+1.68%</span>
                            <img src="/images/graph/btc-graph.svg" />
                        </div>
                        <div className="crypto-graph_element">
                            <span className="crypto-asset text-blue">ETH</span>
                            <span className="crypto-profit">+3.36%</span>
                            <img src="/images/graph/eth-graph.svg" />
                        </div>
                        <div className="crypto-graph_element">
                            <span className="crypto-asset text-blue">LTC</span>
                            <span className="crypto-profit">+2.43%</span>
                            <img src="/images/graph/ltc-graph.svg" />
                        </div>
                        <div className="crypto-graph_element">
                            <span className="crypto-asset text-blue">SOL</span>
                            <span className="crypto-profit">+3.56%</span>
                            <img src="/images/graph/sol-graph.svg" />
                        </div>
                    </div>
                </section>

                <section className="container-donut" id="about-us" style={{scrollMarginTop:"100px"}}>
                    <img
                        src="/images/bg-2.svg"
                        className="bg"
                        draggable={false}
                    />
                    <div className="home_container hero">
                        <div className="hero_block crypto-donut ">
                            <div className="donut-chart">
                              
                                <img src="/images/donut-main.svg" />
                               
                            </div>

                            <div className="assets-block">
                                <div className="assets-block_item">
                                    <p className="assets-block_item-name">
                                        Ethereum
                                    </p>
                                    <p className="assets-block_item-row">
                                        <span className="assets-block_item-symbol">
                                            ETH
                                        </span>
                                        <img
                                            src="/images/arrow-green.svg"
                                            width="12px"
                                            style={{ margin: "0 auto" }}
                                        />
                                        <span className="assets-block_item-growth">
                                            1,8%
                                        </span>
                                    </p>
                                </div>
                                <div className="assets-block_item">
                                    <p className="assets-block_item-name">
                                        Litecoin
                                    </p>
                                    <p className="assets-block_item-row">
                                        <span className="assets-block_item-symbol">
                                            LTC
                                        </span>
                                        <img
                                            src="/images/arrow-green.svg"
                                            width="12px"
                                            style={{ margin: "0 auto" }}
                                        />
                                        <span className="assets-block_item-growth">
                                            1,9%
                                        </span>
                                    </p>
                                </div>{" "}
                                <div className="assets-block_item">
                                    <p className="assets-block_item-name">
                                        Bitcoin
                                    </p>
                                    <p className="assets-block_item-row">
                                        <span className="assets-block_item-symbol">
                                            BTC
                                        </span>
                                        <img
                                            src="/images/arrow-green.svg"
                                            width="12px"
                                            style={{ margin: "0 auto" }}
                                        />
                                        <span className="assets-block_item-growth">
                                            2,1%
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="hero_text">
                            <h1>
                                <span className="text-blue">How </span>
                                does it work?
                            </h1>
                            <p>
                                Our bot works between official exchanges, buying
                                on one and selling on the other at a higher
                                price. You simply choose your plan and receive
                                steady passive income every week.
                            </p>
                            <p className="mobile-show">
                                Join our automated P2P arbitrage bot and earn
                                weekly income from the price difference between
                                exchanges
                            </p>
                            <Link className="home_btn get-started_btn mobile-btn" to="/sign-up">
                            Get started now
                        </Link>

                        </div>
                    </div>
                </section>

                <section className="badge-main">
                    <img
                        src="/images/bg-3.svg"
                        className="bg"
                        draggable={false}
                    />
                    <div className="home_container badge-container">
                        <div className="badge-container_item">
                            <h4>$7 million+</h4>
                            <p>Funds turnover</p>
                        </div>
                        <div className="badge-container_item">
                            <h4>2500+</h4>
                            <p>Users</p>
                        </div>
                        <div className="badge-container_item">
                            <h4>3+ years</h4>
                            <p>On the market</p>
                        </div>
                    </div>
                </section>

                <section className="why-us " id="how-it-works" style={{scrollMarginTop:"100px"}}>
                    <img
                        src="/images/bg-4.svg"
                        className="bg"
                        draggable={false}
                    />
                    <h2 className="home-title">
                        <span className="text-blue">Why</span> us?
                    </h2>
                    <div className="home_container">
                        <div className="advantages-list">
                            <div className="advantages-item">
                                <img src="/images/union.svg" />
                                <p>
                                    An automated bot is a minimum of your time.
                                </p>
                            </div>{" "}
                            <div className="advantages-item">
                                <img src="/images/secure.svg" />
                                <p>
                                    Low risk strategy with guaranteed weekly
                                    income.
                                </p>
                            </div>{" "}
                            <div className="advantages-item">
                                <img src="/images/wallet.svg" />
                                <p>Payouts every week to your account.</p>
                            </div>{" "}
                            <div className="advantages-item">
                                <img src="/images/transparent.svg" />
                                <p>
                                    Transparency and security - all transactions
                                    between official exchanges.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <ChoosePlan />
                <section className="home_container stages">
                    <img
                        src="/images/bg-mobile-stages.svg"
                        className="mobile-bg"
                        draggable={false}
                    />
                    <h2 className="home-title">
                        Stages of work on our{" "}
                        <span className="text-blue">platform:</span>
                    </h2>

                    <div className="stages-list">
                        <div className="stages-item">
                            <span className="stages-number">1</span>
                            <div className="stages-benefits">
                                <p className="select-type text-blue">
                                    Registration
                                </p>
                                <p className="select-undertext">
                                    Register on platform <br />
                                    in minutes
                                </p>
                            </div>
                        </div>
                        <div className="stages-item">
                            <span className="stages-number">2</span>
                            <div className="stages-benefits">
                                <p className="select-type text-blue">
                                    Select account type
                                </p>
                                <p className="select-undertext">
                                    Choose the account that best suits your
                                    needs.
                                </p>
                            </div>
                        </div>{" "}
                        <div className="stages-item">
                            <span className="stages-number">3</span>
                            <div className="stages-benefits">
                                <p className="select-type text-blue">
                                    Account replenishment
                                </p>
                                <p className="select-undertext">
                                    Add funds to the account to activate the bot
                                </p>
                            </div>
                        </div>{" "}
                        <div className="stages-item">
                            <span className="stages-number">4</span>
                            <div className="stages-benefits">
                                <p className="select-type text-blue">
                                    Making a profit
                                </p>
                                <p className="select-undertext">
                                    Follow the results and enjoy the profit from
                                    automated exchanges!
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <Reviews />

                <section className="faq-main" id="faq" style={{scrollMarginTop:"100px"}}>
                    <img
                        src="/images/bg-7.svg"
                        className="bg"
                        draggable={false}
                    />
                     <img
                        src="/images/bg-8.svg"
                        className="bg-2"
                        draggable={false}
                    />
                    <h2 className="home-title">
                        Frequently asked{" "}
                        <span className="text-blue">questions</span>
                    </h2>
                    <div className="home_container">
                        {accordionData.map((item) => (
                            <AccordionItem
                                key={uniqid()}
                                title={item.title}
                                content={item.content}
                            />
                        ))}
                    </div>
                </section>

                <section className="ready">
                   
                    <h2 className="home-title">
                        <span className="text-blue">Ready</span> to get started?
                    </h2>

                    <p className="ready-undertitle">
                        Join hundreds of customers who are already making steady
                        income every week!
                    </p>

                    <Link
                        className="home_btn get-started_btn"
                        style={{ margin: "0 auto" }}
                        to="/sign-up"
                    >
                        Get started now
                    </Link>
                </section>
                <Footer />
            
        </div>
        </Fragment>
    );
};

export default Home;

const accordionData = [
    {
        title: "What is cryptocurrency arbitrage?",
        content:
            "Arbitrage is a strategy that allows you to profit from the price difference of the same cryptocurrency on different exchanges. For example, you can buy Bitcoin on one exchange for $60,000 and sell it on another for $60,500. That $500 price difference is your profit!",
    },
    {
        title: "How does the P2P bot work?",
        content:
            "P2P arbitrage involves buying and selling assets on different platforms or markets with a difference in price. For example, an asset can be bought cheaper on one platform and sold more expensively on another, thanks to price differences. This allows you to make a profit using the difference in exchange rates, without the involvement of intermediaries. Everything happens between two users, which makes the process fast and profitable.",
    },
 
    {
        title: "How long does it take for the first profit?",
        content:
            "Our bot shows daily profits in your personal account, where you can track all transactions. Withdrawals are available at the end of each week, allowing you to receive your earnings regularly.",
    },
    {
        title: "What are the risks of losing investments?",
        content:
            "There are no risks, as our bot operates based on precise algorithms and market analysis. In 3 years of operation, it has never caused any losses to our partners. Thanks to the automation of the process and the use of price differences between exchanges, the bot ensures a stable income without the risks of human error or market fluctuations.",
    },
    {
        title: "What is the company’s benefit?",
        content:
            "Our company earns a commission only from the net profit generated by our automated bot. The commission size depends on the chosen investment package, and you can view this information directly when withdrawing funds on the platform.",
    },
];
