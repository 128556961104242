import React from "react";
import OutsideAlerter from "../../helpers/outsideAlerter";
const DepositPending=(props)=>{

        const {loading,loadingConfirm,loadingTransaction,transaction,closeModalHandler}=props;
    
return(
        <div className="modal-window">
                
        <OutsideAlerter
            className="advance-card advance-card--type-1-v1 aos-init aos-animate modal-main"
            data-aos="fade-up"
            onOutsideClick={() => closeModalHandler()}
        >
            <div className="advance-card__title">
                <h6 className="advance-card__title-text">Deposit</h6>
            </div>
            <div
                className={`advance-card__price ${
                    loading || loadingConfirm ? "blur-loading2x" : ""
                }`}
            >
                <div className="advance-card__price-crypto">
                    <p className="advance-card__price-crypto-value">
                        {!loadingTransaction?transaction.amount.toFixed(2):"1.00"}
                    </p>
                    <p className="advance-card__price-crypto-name">
                        {transaction.wallet.symbol}
                    </p>
                </div>
                <img
                    src="../assets/media/images/icons/arrow-right-white.svg"
                    alt=""
                    className="advance-card__price-arrow"
                />
                <div className="advance-card__price-currency">
                    <p className="advance-card__price-currency-value">
                        {transaction.wallet.rate * transaction.amount}
                    </p>
                    <p className="advance-card__price-currency-name">
                        USD
                    </p>
                </div>
            </div>
            <div
                className={`advance-card__forms-currency ${
                    loading || loadingConfirm ? "blur-loading2x" : ""
                }`}
            >
                <div
                    className={`advance-card__forms-currency--get ${
                        loadingTransaction ? "blur-loading2x" : ""
                    }`}
                >
                    <p
                        className="advance-card__forms-currency__title"
                        style={{ color: "#ffffff" }}
                    >
                        We are waiting for funds to arrive. On average per
                        confirmation of receipt of funds takes 10-15
                        minutes.
                    </p>
                </div>
            </div>
            <button
                            className="advance-card__button btn btn-primary btn-pill"
                            onClick={()=>closeModalHandler()}
                        >
                            Close
                        </button>
        </OutsideAlerter>
    </div> 
)


};


export default DepositPending;