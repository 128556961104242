import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getGrowthUser } from "../../Redux/Actions/userActions";
import { uniqid } from "../../helpers/uniqid";
import { observer } from "mobx-react-lite";
import mobxStore from "../../mobx/mobxStore";
import OutsideAlerter from "../../helpers/outsideAlerter";
const Growth = observer(() => {
    const { variables, setGrowthOpened } = mobxStore;
    const [chartOverAll, setChartOverAll] = useState();
    const dispatch = useDispatch();
    const [timeScale, setTimeScale] = useState([
        {
            text: "1 week",
            axios: "week",
            selected: true,
        },
        {
            text: "1 month",
            axios: "month",
            selected: false,
        },
        {
            text: "1 year",
            axios: "year",
            selected: false,
        },
    ]);
    const userGrowth = useSelector((state) => state.userGrowth);
    const { loading, error, growth } = userGrowth;
    useEffect(() => {
        if (!loading && typeof loading !== "undefined") {
            const chartInfo = getOverallChart(growth);
            setChartOverAll(chartInfo);
        }
    }, [userGrowth]);

    useEffect(() => {
        const scale = timeScale.find((item) => item.selected);
        dispatch(getGrowthUser({ period: scale.axios }));
    }, [dispatch]);

    const timeScaleHandler = (index) => {
        const newTimeScale = timeScale.map((item, i) => {
            if (i === index) {
                return { ...item, selected: true };
            }
            return { ...item, selected: false };
        });
        setTimeScale(newTimeScale);
        dispatch(getGrowthUser({ period: newTimeScale[index].axios }));
    };

    const setGrowthOpenedHandler = (e) => {
        setTimeout(() => {
            setGrowthOpened(false);
        }, 0);
    };

    return (
        <div
            className={`complete-card complete-card--type-2-v1 aos-init aos-animate ${
                loading || typeof loading === "undefined"
                    ? "blur-loading2x"
                    : ""
            }`}
            data-aos="fade-up"
        >
            <div className="complete-card__head">
                <h6 className="complete-card__head-title">Overall Growth</h6>
                <OutsideAlerter onOutsideClick={setGrowthOpenedHandler}>
                    <nav className="navbar">
                        <ul className="complete-card__head-tabs">
                            {timeScale.map((scale) => (
                                <li
                                    className="complete-card__head-tab nav-item"
                                    key={uniqid()}
                                    onClick={() =>
                                        timeScaleHandler(
                                            timeScale.indexOf(scale)
                                        )
                                    }
                                >
                                    <p
                                        className={`complete-card__head-tab-text nav-link ${
                                            scale.selected ? "active" : ""
                                        }`}
                                    >
                                        {scale.text}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </nav>
                    <div className="dropdown complete-card__dropdown">
                        <button
                            className={`btn btn-secondary dropdown-toggle ${
                                variables.growthOpened ? "show" : ""
                            }`}
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={() =>
                                setGrowthOpened(!variables.growthOpened)
                            }
                        >
                            {timeScale.find((item) => item.selected).text}
                            <img
                                src="/assets/media/images/icons/arrow-down.svg"
                                alt=""
                            />
                        </button>
                        <ul
                            className={`dropdown-menu  ${
                                variables.growthOpened ? "show" : ""
                            }`}
                            aria-labelledby="dropdownMenuButton1"
                            id="pills-tab"
                            role="tablist"
                            style={{
                                position: "absolute",
                                inset: "0px 0px auto auto",
                                margin: "0px",
                                transform: "translate3d(0px, 42px, 0px)",
                            }}
                        >
                            {timeScale.map((scale) => (
                                <li
                                    key={uniqid()}
                                    onClick={() =>
                                        timeScaleHandler(
                                            timeScale.indexOf(scale)
                                        )
                                    }
                                >
                                    <a
                                        className={`dropdown-item ${
                                            scale.selected ? "active" : ""
                                        }`}
                                        role="tab"
                                        aria-controls="pills-overall-line-1-m"
                                        aria-selected="true"
                                    >
                                        {scale.text}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </OutsideAlerter>
            </div>
            <div className="tab-content">
                <div className="tab-pane fade show active" role="tabpanel">
                    <div className="complete-card__chart-wrapper">
                        <div
                            id="complete-card__chart-area-1"
                            className="complete-card__chart-render"
                            style={{ minHeight: "365px" }}
                        >
                            {chartOverAll ? (
                                <Chart
                                    options={chartOverAll.options}
                                    series={chartOverAll.series}
                                    height={350}
                                />
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="complete-card__chart-area-vignette--left" />
                        <div className="complete-card__chart-area-vignette--top" />
                        <div className="complete-card__chart-area-vignette--right" />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Growth;

function getOverallChart(growthFoo = []) {
    const series = [];
    const colors = [];
  
    
    let max = 5;

    growthFoo.forEach((item, index) => {
        item.currencies.forEach((currency) => {
            if (!series.find((element) => element.name == currency.symbol)) {
                series.push({
                    name: currency.symbol,
                    type: "line",
                    data: [],
                });
                colors.push(currency.color);
            }
        });
    });

    growthFoo.forEach((item, index) => {
        item.currencies.forEach((currency) => {
            const seriesIndex = series.findIndex(
                (element) => element.name == currency.symbol
            );
            series[seriesIndex].data.push(
                (currency.income24h * 100).toFixed(2)
            );

            if (currency.income24h * 100 > max) {
                max = currency.income24h * 100 + 2;
            }
        });
        // of no transaction just add data 0

        series.forEach((line, dataIndex) => {
            if (line.data.length - 1 < index) {
                series[dataIndex].data.push("0.00");
            }
        });
    });

    const categories = growthFoo.map((item) => item.date);

    var overallAreaChartOpt = {
        series,
        options: {
            chart: {
                height: 350,
                type: "line",
                stacked: false,
                toolbar: { show: false },
                zoom: { enabled: false },
            },
            legend: { show: false },
            stroke: {
                width: [4, 4, 4, 0],
                curve: "smooth",
            },
            colors,
            plotOptions: {
                bar: {
                    columnWidth: "8px",
                },
            },
            fill: {
                gradient: {
                    inverseColors: false,
                    shade: "dark",
                    type: "vertical",
                    opacityFrom: 0.25,
                    opacityTo: 0,
                    stops: [0, 80],
                },
            },
            markers: {
                size: 0,
            },
            xaxis: {
                type: "category",
                categories: categories,
                labels: {
                    style: {
                        colors: "#5D6588",
                        cssClass: "fb-sm",
                    },
                },
            },
            yaxis: {
                min: 0,
                max,
                forceNiceScale: true,
                tickAmount: 5,
                labels: {
                    style: {
                        colors: "#5D6588",
                        cssClass: "fb-sm",
                    },
                    formatter: function (val, index) {
                        val += "%";
                        return val;
                    },
                },
            },
            tooltip: {
                theme: "dark",
            },
        },
    };

    return overallAreaChartOpt;
}
