import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
const ChoosePlan = () => {
    const slidesToShow = 3;

    const [slideIndex, setSlideIndex] = useState(0);
    let sliderRef = useRef(null);
    const settings = {
        className: "centermode",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow,
        speed: 500,
        arrows: false,
        autoplaySpeed: 5000,
        autoplay: true,
        cssEase: "linear",
        beforeChange: (current, next) => setSlideIndex(next),
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 2,
                    //     slidesToScroll: 3,
                    //     infinite: true,
                    //     dots: true
                },
            },
            {
                breakpoint: 870,
                settings: {
                    slidesToShow: 1,
                    //     slidesToScroll: 2,
                    //     initialSlide: 2
                },
            },
            {
                breakpoint:520,
                settings:{
                    centerPadding:"10px",
                    slidesToShow: 1,
                }
            }
        ],
    };
  

    return (
        <section className="choose-plan" id="choose-plan" style={{scrollMarginTop:"120px"}}>
            <img src="/images/bg-5.svg" className="bg" draggable={false} />
            <div className="home_container">
                <h2 className="home-title">
                    Choose your <span className="text-blue">plan</span>
                </h2>
                <div className="slider-container">
                    <Slider
                        {...settings}
                        ref={(slider) => {
                            sliderRef = slider;
                        }}
                    >
                        {plans.map(plan=> <div className="card-plan">
                            <div className="blur"></div>
                            <p className="card-text">{plan.text}</p>
                            <h3 className="card-theme">{plan.theme}</h3>

                            {plan.advs.map(adv=> <p className="card-adv">
                                {adv.text} <b>{adv.bold}</b>
                            </p>)}
                           
                           

                            <Link className="home_btn choose-plan_btn" to="/sign-up">
                                Choose this plan
                            </Link>
                        </div>)}
                       
                      
                    </Slider>
                </div>

                <div className="card-select "></div>
                <div className="slider-bar">
                    <div
                        className="slider-progress"
                        style={{
                            width: (1 / plans.length) * 100 + "%",
                            marginLeft: (slideIndex / plans.length) * 100 + "%",
                        }}
                    ></div>
                </div>
            </div>
        </section>
    );
};

export default ChoosePlan;

const plans = [
    {
        text: "~ Profit per day $5.18 or 1.15%",
        theme: "STANDART",
        advs: [
            { text: "Budget :", bold: " 100 - 500 USD" },
            { text: "Daily exchange limit :", bold: " 1-2" },
            { text: "Transaction revenue :", bold: " 0.8% - 1.5%" },
            { text: "Company share :", bold: " 20%" },
        ],
    }, {
        text: "~ Profit per day $22.28 or 1.35%",
        theme: "SILVER",
        advs: [
                { text: "Budget :", bold: " 500 - 2.000 USD" },
                { text: "Daily exchange limit :", bold: " 1-2" },
                { text: "Transaction revenue :", bold: " 1% - 1.7%" },
                { text: "Company share :", bold: " 17%" },
            ],
    }, {
        text: "~ Profit per day $241.67 or 1.93%",
        theme: "INVEST",
        advs: [
                { text: "Budget :", bold: " 2.000 - 8.000 USD" },
                { text: "Daily exchange limit :", bold: " 2-3" },
                { text: "Transaction revenue :", bold: " 1.3% - 2.5%" },
                { text: "Company share :", bold: " 15%" },
            ],
    }, {
        text: "~ Profit per day $1068.75 or 2.38%",
        theme: "PRIME",
        advs: [
                { text: "Budget :", bold: " 8.000 - 22.000 USD" },
                { text: "Daily exchange limit :", bold: " 2-4" },
                { text: "Transaction revenue :", bold: " 1.5%-3%" },
                { text: "Company share :", bold: " 13%" },
            ],
    }, {
        text: "~ Profit per day $2604.80+ or 2.96%+",
        theme: "INDIVIDUAL",
        advs: [
                { text: "Budget :", bold: " 22.000+ USD" },
                { text: "Daily exchange limit :", bold: " 3-5" },
                { text: "Transaction revenue :", bold: " 1.8%-4%" },
                { text: "Company share :", bold: " 10%" },
            ],
    },
];
