export const checkIfOneIsTrue=(variables)=>{
      
                // check if one key of parameter is true
                for(var key in variables){
                    if (key === 'growthOpened') {
                        continue; // Skip this iteration if the key is 'growthOpened'
                    }
                    if(variables[key]==true ){
                    
                        
                        return true;
                    }
                }
            
                return false;
            
};