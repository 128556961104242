import axios from "axios";
import { serverLink } from "../../App";
import { getDataOrFetch } from "../../helpers/optimizationFetch";

import {
    GET_CURRENCIES_REQUEST,
    GET_CURRENCIES_SUCCESS,
    GET_CURRENCIES_FAIL,
    GET_CURRENCIES_RESET,
    GET_WALLETS_REQUEST,
    GET_WALLETS_SUCCESS,
    GET_WALLETS_FAIL,
    GET_WALLETS_RESET,
    CREATE_DEPOSIT_REQUEST,
    CREATE_DEPOSIT_SUCCESS,
    CREATE_DEPOSIT_FAIL,
    CREATE_DEPOSIT_RESET,
    GET_TRANSACTION_REQUEST,
    GET_TRANSACTION_SUCCESS,
    GET_TRANSACTION_FAIL,
    GET_TRANSACTION_RESET,
    REF_TRANSFER_REQUEST,
    REF_TRANSFER_SUCCESS,
    REF_TRANSFER_FAIL,
    REF_TRANSFER_RESET,
    CREATE_WITHDRAW_REQUEST,
CREATE_WITHDRAW_SUCCESS,
CREATE_WITHDRAW_FAIL,
CREATE_WITHDRAW_RESET,
CONFIRM_DEPOSIT_REQUEST,
CONFIRM_DEPOSIT_SUCCESS,
CONFIRM_DEPOSIT_FAIL,
CONFIRM_DEPOSIT_RESET,
GET_COMISSION_WITHDRAW_REQUEST,
GET_COMISSION_WITHDRAW_SUCCESS,
GET_COMISSION_WITHDRAW_FAIL,
GET_COMISSION_WITHDRAW_RESET
} from "../Constans/transactionsConstans";
import { logout } from "./userActions";
export const getCurrencies = () => async (dispatch, getState) => {
    try {
        dispatch({ type: GET_CURRENCIES_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const data = await getDataOrFetch(
            `${serverLink}/api/transaction/currencies`,
            "getCurrencies",
            config
        );
        dispatch({ type: GET_CURRENCIES_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: GET_CURRENCIES_FAIL,
            payload: message,
        });
    }
};

export const getWallets = () => async (dispatch, getState) => {
    try {
        dispatch({ type: GET_WALLETS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };
      
        const data = await getDataOrFetch(
            `${serverLink}/api/transaction/wallets`,
            "getWallets",
            config
        );
        dispatch({ type: GET_WALLETS_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: GET_WALLETS_FAIL,
            payload: message,
        });
    }
};

export const createDeposit = (props) => async (dispatch, getState) => {
    try {
        dispatch({ type: CREATE_DEPOSIT_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.post(
            `${serverLink}/api/transaction/deposit`,
            props,
            config
        );
        dispatch({ type: CREATE_DEPOSIT_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: CREATE_DEPOSIT_FAIL,
            payload: message,
        });
    }
};

export const getTransaction = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: GET_TRANSACTION_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.get(
            `${serverLink}/api/transaction/${id}`,

            config
        );
        dispatch({ type: GET_TRANSACTION_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: GET_TRANSACTION_FAIL,
            payload: message,
        });
    }
};

export const makeRefTransfer = () => async (dispatch, getState) => {
    try {
        dispatch({ type: REF_TRANSFER_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.post(
            `${serverLink}/api/transaction/transfer`,
            {},
            config
        );
        dispatch({ type: REF_TRANSFER_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: REF_TRANSFER_FAIL,
            payload: message,
        });
    }
};

export const createWithdraw = (props) => async (dispatch, getState) => {
    try {
        dispatch({ type: CREATE_WITHDRAW_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.post(
            `${serverLink}/api/transaction/withdraw`,
            props,
            config
        );
        dispatch({ type: CREATE_WITHDRAW_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: CREATE_WITHDRAW_FAIL,
            payload: message,
        });
    }
};

export const confirmDeposit = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONFIRM_DEPOSIT_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.put(
            `${serverLink}/api/transaction/deposit/${id}`,
            {},
            config
        );
        dispatch({ type: CONFIRM_DEPOSIT_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: CONFIRM_DEPOSIT_FAIL,
            payload: message,
        });
    }
};

export const getCommisionWithdraw = () => async (dispatch, getState) => {
    try {
        dispatch({ type: GET_COMISSION_WITHDRAW_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };
        const data = await getDataOrFetch(
            `${serverLink}/api/transaction/commision-withdraw`,
            "getCommisionWithdraw",
            config
        );
      
        dispatch({ type: GET_COMISSION_WITHDRAW_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: GET_COMISSION_WITHDRAW_FAIL,
            payload: message,
        });
    }
};