import React, { useEffect, useState } from "react";
import DonutChart from "./donutChart";
import { useDispatch, useSelector } from "react-redux";
import { getUserBalanceInfo } from "../../Redux/Actions/userActions";

const Balance = (props) => {
    // get props

    const dispatch = useDispatch();

    const balanceInfo = useSelector((state) => state.balanceInfo);

    const { loading: loadingBalance, balance, error } = balanceInfo;

    useEffect(() => {
        dispatch(getUserBalanceInfo());
    }, [dispatch]);
    const { userDetails, transactions } = props;
    const { user, loading } = userDetails;

    const [income24h, setIncome24H] = useState(0);
    console.log(balanceInfo);

    return (
        <div
            className={`complete-card complete-card--type-1-v1 aos-init aos-animate ${
                !loading && !user ? "blur-loading2x" : ""
            }`}
            data-aos="fade-up"
        >
            <div className="complete-card__balance">
                <div className="complete-card__balance-head">
                    <div className="complete-card__balance-head__title">
                        <h6 className="complete-card__balance-head__title-text">
                            Balance
                        </h6>
                        <div className="complete-card__balance-head__title-influsion">
                            <img
                                src="/assets/media/images/icons/arrow-bullish.svg"
                                alt=""
                                className="complete-card__balance-head__title-influsion-icon"
                            />
                            <p className="fb-regular fb-regular--bold complete-card__balance-head__title-influsion-value text-bullish">
                                {(income24h * 100).toFixed(2)}%
                            </p>
                        </div>
                    </div>
                    <p
                        className={`complete-card__balance-head__value ${
                            loading ? "blur-loading" : ""
                        }`}
                    >
                        USD{" "}
                        {!loading && user && user.balance
                            ? (
                                  user.balance.income - user.balance.expenses
                              ).toFixed(2)
                            : "12 345.21"}
                    </p>
                </div>
                <div className="complete-card__balance-revenue">
                    <div className="complete-card__balance-revenue__income">
                        <div className="balance-revenue__income-title">
                            <img
                                src="/assets/media/images/icons/arrow-bullish.svg"
                                alt=""
                                className="balance-revenue__income-title-icon"
                            />
                            <p className="fb-regular balance-revenue__income-title-text">
                                Income
                            </p>
                        </div>
                        <p className="fd-sm fd-sm--bold balance-revenue__income-value">
                            USD{" "}
                            {/* {!loading && user && user.balance
                                ? user.balance.income.toFixed(2)
                                : "12 345.21"} */}
                            {/* {transactions?getIncome(transactions).toFixed(2):""} */}
                            {!loadingBalance &&
                            typeof loadingBalance !== "undefined"
                                ? balance.earned.toFixed(2)
                                : "0"}
                        </p>
                    </div>
                    <div className="complete-card__balance-revenue__separator" />
                    <div className="complete-card__balance-revenue__expenses">
                        <div className="balance-revenue__expenses-title">
                            <img
                                src="/assets/media/images/icons/arrow-bearish.svg"
                                alt=""
                                className="balance-revenue__expenses-title-icon"
                            />
                            <p className="fb-regular balance-revenue__expenses-title-text">
                                Expenses
                            </p>
                        </div>
                        <p className="fd-sm fd-sm--bold balance-revenue__expenses-value">
                            USD{" "}
                            {!loading && user && user.balance
                                ? (
                                  (!loadingBalance &&
                                  typeof loadingBalance !== "undefined"
                                      ? balance.dollarsCommission
                                      : 0)).toFixed(2)
                                : "12 345.21"}
                        </p>
                    </div>
                </div>
            </div>
            <div className="complete-card__separator" />
            <DonutChart setIncome24H={setIncome24H} />
        </div>
    );
};

export default Balance;

function getIncome(transactions) {
    let sum = 0;
    for (let i = 0; i < transactions.length; i++) {
        try {
            sum += transactions[i].amount - transactions[i].referrer.amount;
        } catch (error) {
            continue;
        }
    }
    return sum;
}
