export const USER_LOGIN_REQUEST="USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS="USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL="USER_LOGIN_FAIL";
export const USER_LOGOUT="USER_LOGOUT";                                                                                        

export const USER_FORGOT_REQUEST="USER_FORGOT_REQUEST";
export const USER_FORGOT_SUCCESS="USER_FORGOT_SUCCESS";
export const USER_FORGOT_FAIL="USER_FORGOT_FAIL";
export const USER_FORGOT_RESET="USER_FORGOT_RESET";



export const USER_PASSWORD_CHANGE_REQUEST="USER_PASSWORD_CHANGE_REQUEST";
export const USER_PASSWORD_CHANGE_SUCCESS="USER_PASSWORD_CHANGE_SUCCESS";
export const USER_PASSWORD_CHANGE_FAIL="USER_PASSWORD_CHANGE_FAIL";
export const USER_PASSWORD_CHANGE_RESET="USER_PASSWORD_CHANGE_RESET";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";


export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

export const USER_TRANSACTIONS_REQUEST = "USER_TRANSACTIONS_REQUEST";
export const USER_TRANSACTIONS_SUCCESS = "USER_TRANSACTIONS_SUCCESS";
export const USER_TRANSACTIONS_FAIL = "USER_TRANSACTIONS_FAIL";
export const USER_TRANSACTIONS_RESET = "USER_TRANSACTIONS_RESET";

export const USER_DONUT_STATS_REQUEST = "USER_DONUT_STATS_REQUEST";
export const USER_DONUT_STATS_SUCCESS = "USER_DONUT_STATS_SUCCESS";
export const USER_DONUT_STATS_FAIL = "USER_DONUT_STATS_FAIL";
export const USER_DONUT_STATS_RESET = "USER_DONUT_STATS_RESET";

export const USER_DONUT_STATS7D_REQUEST = "USER_DONUT_STATS7D_REQUEST";
export const USER_DONUT_STATS7D_SUCCESS = "USER_DONUT_STATS7D_SUCCESS";
export const USER_DONUT_STATS7D_FAIL = "USER_DONUT_STATS7D_FAIL";
export const USER_DONUT_STATS7D_RESET = "USER_DONUT_STATS7D_RESET";

export const USER_GROWTH_REQUEST = "USER_GROWTH_REQUEST";
export const USER_GROWTH_SUCCESS = "USER_GROWTH_SUCCESS";
export const USER_GROWTH_FAIL = "USER_GROWTH_FAIL";
export const USER_GROWTH_RESET = "USER_GROWTH_RESET";

export const USER_BALANCE_STATS_REQUEST = "USER_BALANCE_STATS_REQUEST";
export const USER_BALANCE_STATS_SUCCESS = "USER_BALANCE_STATS_SUCCESS";
export const USER_BALANCE_STATS_FAIL = "USER_BALANCE_STATS_FAIL";
export const USER_BALANCE_STATS_RESET = "USER_BALANCE_STATS_RESET";

export const STATUS_REFERRAL_REQUEST = "STATUS_REFERRAL_REQUEST";
export const STATUS_REFERRAL_SUCCESS = "STATUS_REFERRAL_SUCCESS";
export const STATUS_REFERRAL_FAIL = "STATUS_REFERRAL_FAIL";
export const STATUS_REFERRAL_RESET = "STATUS_REFERRAL_RESET";

export const GET_TARIFF_REQUEST = "GET_TARIFF_REQUEST";
export const GET_TARIFF_SUCCESS = "GET_TARIFF_SUCCESS";
export const GET_TARIFF_FAIL = "GET_TARIFF_FAIL";
export const GET_TARIFF_RESET = "GET_TARIFF_RESET";

export const GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL";
export const GET_NOTIFICATIONS_RESET = "GET_NOTIFICATIONS_RESET";

export const GET_TARIFFS_LIST_REQUEST = "GET_TARIFFS_LIST_REQUEST";
export const GET_TARIFFS_LIST_SUCCESS = "GET_TARIFFS_LIST_SUCCESS";
export const GET_TARIFFS_LIST_FAIL = "GET_TARIFFS_LIST_FAIL";
export const GET_TARIFFS_LIST_RESET = "GET_TARIFFS_LIST_RESET";

export const USER_WALLET_ACTIONS_REQUEST = "USER_WALLET_ACTIONS_REQUEST";
export const USER_WALLET_ACTIONS_SUCCESS = "USER_WALLET_ACTIONS_SUCCESS";
export const USER_WALLET_ACTIONS_FAIL = "USER_WALLET_ACTIONS_FAIL";
export const USER_WALLET_ACTIONS_RESET = "USER_WALLET_ACTIONS_RESET";

export const GET_CURRENCIES_REQUEST = "GET_CURRENCIES_REQUEST";
export const GET_CURRENCIES_SUCCESS = "GET_CURRENCIES_SUCCESS";
export const GET_CURRENCIES_FAIL = "GET_CURRENCIES_FAIL";
export const GET_CURRENCIES_RESET = "GET_CURRENCIES_RESET";

export const USER_IMAGE_DELETE_REQUEST = "USER_IMAGE_DELETE_REQUEST";
export const USER_IMAGE_DELETE_SUCCESS = "USER_IMAGE_DELETE_SUCCESS";
export const USER_IMAGE_DELETE_FAIL = "USER_IMAGE_DELETE_FAIL";
export const USER_IMAGE_DELETE_RESET = "USER_IMAGE_DELETE_RESET";

export const USER_BALANCE_INFO_REQUEST = "USER_BALANCE_INFO_REQUEST";
export const USER_BALANCE_INFO_SUCCESS = "USER_BALANCE_INFO_SUCCESS";
export const USER_BALANCE_INFO_FAIL = "USER_BALANCE_INFO_FAIL";
export const USER_BALANCE_INFO_RESET = "USER_BALANCE_INFO_RESET";