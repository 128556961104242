import React from "react";
import OutsideAlerter from "../../helpers/outsideAlerter";
import Toast from "../Toast/toast";
import { getIconFromServer } from "../../helpers/getImageFromServer";
import { uniqid } from "../../helpers/uniqid";
const WithdrawDefault = (props) => {
    const {
        withdrawHandler,
        loadingTariff,
        setWallet,
        wallet,
        amount,
        onOutsideClickHandler,
        success,
        getReceiveAmount,
        tariff,
        setAmount,
        noCommision,
        balance,

        currencySelectOpened,
        setCurrencySelectedHandler,
        currencySelected,
        setCurrencySelectOpened,
        currencies,
        personalBalance,
        earned,
        subType,
        setSubType
    } = props;

    return (
        <React.Fragment>
            <Toast/>
            <div className={`modal-window`}>
                <OutsideAlerter
                    className={`advance-card advance-card--type-1-v1 aos-init aos-animate ${
                        success ? "blur-modal" : ""
                    }`}
                    onOutsideClick={() => {
                        onOutsideClickHandler();
                    }}
                >
                    <div className="advance-card__title">
                        <h6 className="advance-card__title-text">Withdraw</h6>
                    </div>
                   

                    <div className="advance-card__forms-currency">
                        <div className="advance-card__forms-currency--pay">
                            <p className="advance-card__forms-currency__title">
                                Get
                            </p>
                            <div className="forms-currency forms-currency--advance-card">
                                <div className="forms-group forms-group--value">
                                    <input
                                        type="number"
                                        placeholder="Enter amount"
                                        min={0}
                                        value={amount}
                                        onChange={(e) =>
                                            setAmount(e.target.value)
                                        }
                                        className="form-control forms-currency__value"
                                    />
                                </div>

                                <div
                                    className="forms-group forms-group--currency js-forms-group--currency"
                                    id="custom-select-currency-2"
                                >
                                    <div
                                        className="forms-group__items selected"
                                        data-selected="usd"
                                    >
                                        <img
                                            className="fg-items__icon"
                                            src="/assets/media/images/icons/usd.svg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="advance-card__forms-currency--get">
                            <p className="advance-card__forms-currency__title">
                                Wallet
                            </p>
                            <div className="forms-currency forms-currency--advance-card">
                                <div className="forms-group forms-group--value">
                                    <input
                                        type="text"
                                        placeholder={`${currencySelected!="Card"?"Network: TRC20":"Enter your card"}`}
                                        value={wallet}
                                        onChange={(e) =>
                                            setWallet(e.target.value)
                                        }
                                        className="form-control forms-currency__value form-control_placeholder_sm"
                                    />
                                </div>
                                <div className="forms-currency__line" />
                                <div
                                    className="forms-group forms-group--currency js-forms-group--currency"
                                    id="custom-select-currency-2"
                                    onClick={() =>
                                        setCurrencySelectOpened(true)
                                    }
                                >
                                    <div
                                        className="forms-group__items selected"
                                        data-selected="usd"
                                    >
                                        <img
                                            className="fg-items__icon"
                                            src={
                                                getIconFromServer(
                                                        currencies.find(
                                                              (wallet) =>
                                                                  wallet.name ===
                                                                  currencySelected
                                                          ).icon
                                                      )
                                                  
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <img
                                        className="forms-currency__icon-arrow-down"
                                        src="../assets/media/images/icons/arrow-down.svg"
                                        alt=""
                                    />
                                    <OutsideAlerter
                                        className={`forms-group__dropdown js-forms-group__dropdown ${
                                            currencySelectOpened
                                                ? "is-open"
                                                : ""
                                        }`}
                                        onOutsideClick={() =>
                                            setCurrencySelectOpened(false)
                                        }
                                    >
                                        {currencies.map((wallet) => (
                                                  <div
                                                      className="forms-group__items"
                                                      data-selected={
                                                          wallet.symbol
                                                      }
                                                      onClick={() =>
                                                          setCurrencySelectedHandler(
                                                              wallet.name
                                                          )
                                                      }
                                                      key={uniqid()}
                                                  >
                                                      <img
                                                          className="fg-items__icon"
                                                          src={getIconFromServer(
                                                              wallet.icon
                                                          )}
                                                          alt=""
                                                      />
                                                  </div>
                                              ))
                                            }
                                    </OutsideAlerter>
                                </div>
                                {/* <OutsideAlerter
                                className={`forms-group forms-group--currency js-forms-group--currency ${currencySelectOpened?"is-open":""}`}
                                id="custom-select-currency-1"
                            >
                                <div
                                    className="forms-group__items selected"
                                    data-selected="usd"
                                >
                                    <img
                                        className="fg-items__icon"
                                        src="/assets/media/images/icons/logo-usdt.svg"
                                        alt=""
                                    />
                                </div>
                            </OutsideAlerter> */}
                            </div>
                        </div>
                    </div>
                    <div className="advance-card__title" onClick={()=>setSubType("Deposit")}>
                        <p className="advance-card__form-title-text" style={{cursor:"pointer",fontWeight:`${subType=="Deposit"?"bold":""}`,textDecoration:`${subType=="Deposit"?"underline":""}`}}>
                            Your personal balance: {personalBalance.toFixed(2)}
                        </p>
                    </div>
                    <div className="advance-card__title" onClick={()=>setSubType("Income")}>
                        <p className="advance-card__form-title-text" style={{cursor:"pointer",fontWeight:`${subType=="Income"?"bold":""}`,textDecoration:`${subType=="Income"?"underline":""}`}}>
                            Your net income: {earned.toFixed(2)}
                        </p>
                    </div>
                    <div className="advance-card__title">
                        <p className="advance-card__form-title-text">
                            Company fee:{" "}
                            {!loadingTariff && tariff
                                ? `-${tariff.commission}% Tariff - ${tariff.name}`
                                : "Standart (20%)"}
                        </p>
                    </div>

                    <div className="advance-card__title">
                        <p>
                            Receives to the wallet:{" "}
                            {!loadingTariff && tariff
                                ? getReceiveAmount(
                                      amount,
                                      noCommision,
                                      tariff.commission,subType
                                  )
                                : "..."}{" "}
                            USD
                        </p>
                    </div>
                    <button
                        className="advance-card__button btn btn-primary btn-pill"
                        onClick={withdrawHandler}
                    >
                        Withdraw
                    </button>
                </OutsideAlerter>
            </div>
        </React.Fragment>
    );
};

export default WithdrawDefault;
